import React from 'react'

function Info(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 11 11"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M5.5 0a5.5 5.5 0 100 11 5.5 5.5 0 000-11zm.73 9.17H4.77v-4.4h1.46v4.4zm0-5.5H4.77V2.2h1.46v1.47z"
            />
        </svg>
    )
}

export default Info
