import React from 'react'

function SVGCar(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            height={props.height}
            width={props.width}
            viewBox="0 0 22 18"
        >
            <path
                fill={props.colorOne}
                d="M22 6.3c0-.742-.606-1.35-1.347-1.35h-1.437L18.05 1.755c-.18-.472-.696-.99-1.145-1.147 0 0-1.616-.586-5.882-.586-4.265 0-5.881.586-5.881.586-.472.18-.988.697-1.145 1.147L2.784 4.95H1.347C.607 4.95 0 5.557 0 6.3c0 .743.606 1.35 1.347 1.35h.426l-.359.945c-.157.473-.292 1.26-.292 1.755v5.85c0 .99.809 1.8 1.796 1.8.988 0 1.796-.81 1.796-1.8v-.9h12.572v.9c0 .99.808 1.8 1.796 1.8.987 0 1.796-.81 1.796-1.8v-5.85c0-.495-.135-1.282-.315-1.755l-.336-.945h.426c.74 0 1.347-.607 1.347-1.35zM5.837 2.25h10.326L17.51 6.3H4.49l1.347-4.05zM4.265 11.7c-.74 0-1.347-.607-1.347-1.35 0-.742.606-1.35 1.347-1.35s1.347.607 1.347 1.35c0 .742-.606 1.35-1.347 1.35zm13.47 0c-.741 0-1.347-.607-1.347-1.35 0-.742.606-1.35 1.347-1.35.74 0 1.347.607 1.347 1.35 0 .742-.606 1.35-1.347 1.35z"
            />
        </svg>
    )
}

export default SVGCar
