import React from 'react'

function Plus(props) {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1 3.83333C0.447715 3.83333 -1.87131e-07 4.28105 -2.11272e-07 4.83333C-2.35413e-07 5.38562 0.447715 5.83333 1 5.83333L4.16667 5.83333L4.16667 9C4.16667 9.55228 4.61438 10 5.16667 10C5.71895 10 6.16667 9.55228 6.16667 9L6.16667 5.83333L9 5.83333C9.55228 5.83333 10 5.38562 10 4.83333C10 4.28105 9.55228 3.83333 9 3.83333L6.16667 3.83333L6.16667 1C6.16667 0.447715 5.71895 -1.87131e-07 5.16667 -2.11272e-07C4.61438 -2.35413e-07 4.16667 0.447715 4.16667 1L4.16667 3.83333L1 3.83333Z"
        fill={props.colorOne}
        strokeWidth={0}
      />
    </svg>
  )
}

export default Plus
