import 'react-app-polyfill/ie11'
import { hot } from 'react-hot-loader'
import React from 'react'
import { render } from 'react-dom'
import configureStore, { history } from '../store/configureStore'

import { Rehydrate } from './Rehydrate'
import Root from './Root'

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const { store, persistor } = configureStore()
const app = document.getElementById('app')

// - Rehydrate SSR (server side render)
Rehydrate()

// - Render main SPA (single page application)
if (app) {
  if (process.env.NODE_ENV === 'development' && module.hot) {
    const HotRoot = hot(module)(() => (
      <Root store={store} history={history} persistor={persistor} />
    ))

    render(<HotRoot />, app)
    module.hot.accept('./Root', () => {
      const NewRoot = require('./Root').default

      const NewHotRoot = hot(module)(() => (
        <NewRoot store={store} history={history} persistor={persistor} />
      ))
      render(<NewHotRoot />, app)
    })
  } else {
    Sentry.init({
      dsn: 'https://675ea74b2c2a47a59bf73fb850a5a59f@o159203.ingest.sentry.io/1221145',
      integrations: [new Integrations.BrowserTracing()],

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    })

    render(<Root store={store} history={history} persistor={persistor} />, app)
  }
}
