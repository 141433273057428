import React from 'react'

function Address(props) {
    return (
		<svg 
			viewBox="0 0 64 63" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg"
			height={props.height}
			width={props.width}
		>
			<path className="svg-stroke" d="M49.2908 23.177V46.6424C49.2908 48.6044 47.6975 50.1896 45.7255 50.1896H19.2744C17.3023 50.1896 15.709 48.6044 15.709 46.6424V23.177" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
			<path className="svg-stroke" d="M54.6847 26.7312L32.4992 12.123L10.3066 26.7312" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
			<path className="svg-stroke" d="M32.4996 33.8398C35.2576 33.8398 37.5012 36.0719 37.5012 38.8159V50.1896H27.498V38.8159C27.498 36.0719 29.7416 33.8398 32.4996 33.8398Z" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
			<path className="svg-stroke" d="M17.0176 21.7552V13.559C17.0176 12.5922 18.0036 11.8103 19.2183 11.8103H21.962C23.1766 11.8103 24.1627 12.5922 24.1627 13.559V17.4972" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
    )
}

export default Address