import React from 'react'

import { createUseStyles } from 'react-jss'
import Cleave from 'cleave.js/react'

const useStyles = createUseStyles(theme => ({
  labelWrapper: {
    width: '100%',
    display: 'block',
  },
  labelInput: {
    backgroundColor: '#ffffff',
    border: '1px solid #DDDDDD',
    borderRadius: '14px',
    boxShadow: 'none',
    boxSizing: 'border-box',
    color: '#000',
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: theme.fontFamily,
    letterSpacing: '.5px',
    padding: '15px 20px',
    width: '100%',
    '&::placeholder': {
      color: '#000000',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
      letterSpacing: '0.02em',
    },
  },
  labelInputGrey: {
    backgroundColor: '#F7F7F7',
    border: '1px solid #F7F7F7',
    borderRadius: '17px',
    boxShadow: 'none',
    boxSizing: 'border-box',
    color: '#000',
    fontSize: 12,
    lineHeight: '18px',
    fontFamily: theme.fontFamily,
    letterSpacing: '.5px',
    padding: '15px 20px',
    width: '100%',
    '&::placeholder': {
      color: '#2D2D2D',
      fontSize: '12px',
      fontWeight: 500,
      lineHeight: '18px',
      letterSpacing: '0.02em',
    },
  },
  labelParagraph: {
    marginBottom: 10,
    marginTop: 20,

    '@media (max-width: 769px)': {
      marginTop: '15px !important',
    },
  },
  error: {
    '& input': {
      borderColor: '#EE2049',
    },
    '& .error': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 12,
      paddingTop: 7,
      color: '#EE2049',
    },
    '& .error img': {
      width: 12,
      height: 12,
      marginTop: 2,
      marginRight: 5,
    },
  },
}))

const FormInput = ({
  labelText,
  inputName,
  inputType,
  placeholder,
  maskType,
  maskOptions,
  defaultValue,
  error,
  onChange,
  greyBackground,
  required,
  lessPadding,
  noMarginBottom,
  readOnly,
  prefix,
}) => {
  const classes = useStyles()

  if (inputType === 'masked') {
    /*
      If something is "masked" we can use 3 different standardised examples by setting a
      maskType as either `credit`, `expiry` or `dob`. If you don't add one of these types,
      it will default to the parameters passed through as "maskOptions" and "placeholder"
      allowing you to use Cleave library from higher in the react component tree.
    */
    let options = maskOptions
    let placeholderMasked = placeholder

    if (maskType === 'credit') {
      options = {
        creditCard: true,
        delimiter: ' - ',
      }
      placeholderMasked = ''
    } else if (maskType === 'aamembernumber') {
      options = {
        delimiter: '',
        numeral: true,
      }
      // placeholderMasked = '3083 26 xxxxxxxxxx'
    } else if (maskType === 'expiry') {
      options = {
        date: true,
        delimiter: ' / ',
        datePattern: ['m', 'y'],
      }
      placeholderMasked = 'MM / YY'
    } else if (maskType === 'date') {
      options = {
        date: true,
        delimiter: ' - ',
        datePattern: ['d', 'm', 'Y'],
      }
      placeholderMasked = labelText + ' DD - MM - YYYY'
    }

    return (
      <div
        className={`${error && classes.error}`}
        style={noMarginBottom ? {} : { marginBottom: 20 }}
      >
        <label
          htmlFor={inputName}
          className={`${classes.labelWrapper} ${error && classes.error}`}
          style={
            inputName === 'customer.aamembernumber'
              ? {
                  display: 'flex',
                }
              : {}
          }
        >
          {inputName === 'customer.aamembernumber' && (
            <span
              htmlFor={inputName}
              style={{
                color: '#777',
                fontSize: '12px',
                padding: '16px 0 0 20px',
                width: '75px',
                fontFamily: 'AvenirNextLTPro, Tahoma, sans-serif',
                borderRadius: '17px 0 0 17px',
                backgroundColor: '#e9e9e9',
                border: '1px solid #e9e9e9',
              }}
            >
              {prefix}{' '}
            </span>
          )}
          <Cleave
            maxLength={inputName === 'customer.aamembernumber' ? 10 : undefined}
            minLength={inputName === 'customer.aamembernumber' ? 10 : undefined}
            className={greyBackground ? classes.labelInputGrey : classes.labelInput}
            placeholder={placeholderMasked}
            options={options}
            id={inputName}
            name={inputName}
            value={defaultValue}
            onChange={onChange}
            required={required}
            style={
              lessPadding
                ? { padding: '9px 14px' }
                : inputName === 'customer.aamembernumber'
                ? {
                    borderRadius: '0 17px 17px 0',
                    paddingLeft: '5px',
                  }
                : {}
            }
            readOnly={readOnly}
          />
        </label>
        {error && (
          <div className="error">
            <img src="/themes/thrifty/assets/images/errorex.png" />
            {error.join(', ')}
          </div>
        )}
      </div>
    )
  }

  return (
    <div className={`${error && classes.error}`} style={noMarginBottom ? {} : { marginBottom: 20 }}>
      <label htmlFor={inputName} className={`${classes.labelWrapper} ${error && classes.error}`}>
        <input
          placeholder={labelText}
          className={greyBackground ? classes.labelInputGrey : classes.labelInput}
          type={inputType}
          name={inputName}
          defaultValue={defaultValue}
          onChange={onChange}
          required={required}
          style={lessPadding ? { padding: '9px 14px' } : {}}
          readOnly={readOnly}
        />
      </label>
      {error && error.join(', ') && (
        <div className="error">
          <img src="/themes/thrifty/assets/images/errorex.png" />
          {error.join(', ')}
        </div>
      )}
    </div>
  )
}

export default FormInput
