import _ from 'lodash'
import numeral from 'numeral'

export const sanctionOptions = (quotation, models, context, coverItems) => {
  const currentTrip = quotation.trips[quotation.trip]
  const selectedCover = _.find(coverItems, { selected: true })
  const features = _.get(selectedCover, 'features', [])
  const restrictedOptions = _.get(models, `vehicles.${currentTrip.car}.restricted_options`, [])
  const options = _.chain(currentTrip.options)
    .filter(
      o =>
        o['@attributes'].hasOwnProperty('liability') == false &&
        o['@attributes'].available == 'true',
    )
    .filter(o => !_.some(restrictedOptions, v => v == o['@attributes'].code))
    .filter(o => !_.some(features, { option_code: o['@attributes'].code, included: '1' }))
    .filter(
      o => _.get(_.find(models.options, { code: o['@attributes'].code }), 'max_quantity', 0) > 0,
    )
    .value()
  return options
}

export const mutateQuotationWithExtras = (newExtras, quotation) => {
  return {
    data: {
      ...quotation,
      trips: {
        ...quotation.trips,
        [quotation.trip]: {
          ...quotation.trips[quotation.trip],
          extras: newExtras,
        },
      },
    },
  }
}
