import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { useBoolean } from '@rothzerg/useboolean'
import SVG from '../SVG/SVG'
import { LocationContext } from '../../../contexts/Locations'

const capitalizeFirstLetter = string => {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const useStyles = createUseStyles(theme => ({
  locationCard: {
    fontFamily: theme.fontFamily,
    fontSize: '15px',
    lineHeight: '20px',
    padding: '25px 0 20px 0',
    borderBottom: '1px solid #e1e5e7',
  },
  locationTitle: {
    color: theme.colors.blue,
    fontFamily: theme.fontFamily,
    fontSize: 18,
    fontWeight: 'bold',
    letterSpacing: '0.1px',
    padding: 0,
    margin: '0 0 23px 0',
  },
  locationAddress: {
    display: 'flex',
    flexDirection: 'row',
  },
  locationAddressText: {
    margin: '0',
    fontSize: 14,
    lineHeight: '22px',
    letterSpacing: '0.02em',
  },
  locationPhones: {
    display: 'flex',
    flexDirection: 'row',
    fontFamily: 'inherit',
    fontSize: 'inherit',
    lineHeight: 'inherit',
    '@media (max-width: 600px)': {
      flexDirection: 'column',
    },
  },
  locationPhoneHalf: {
    display: 'flex',
    flexDirection: 'row',
    width: '50%',
    marginBottom: 6,
  },
  locationPhonesValue: {
    flexGrow: 1,
    flexShrink: 0,
    width: '100%',
    '& p': {
      margin: 0,
      padding: 0,
      fontFamily: 'inherit',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      marginBottom: 7,
      '& span:first-child': {
        fontSize: 15,
        lineHeight: '18px',
        fontWeight: 500,
        display: 'block',
      },
      '& span:nth-child(2)': {
        fontSize: 16,
        lineHeight: '22px',
        fontWeight: 300,
        display: 'block',
      },
      '& a': {
        color: '#000',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
  locationPhonesValueTimes: {
    position: 'relative',
    '& div p': {
      display: 'none',
      '&:first-child': {
        display: 'block',
      },
    },
  },
  locationPhonesValueTimesShow: {
    position: 'relative',
    '& div p': {
      display: 'block',
      '&:first-child': {
        display: 'block',
      },
    },
  },
  locationDetailLink: {
    backgroundColor: theme.colors.orange,
    color: theme.colors.white,
    fontSize: 15,
    fontWeight: 700,
    textDecoration: 'none',
    lineHeight: '17px',
    display: 'inline-block',
    padding: '13px 25px',
    borderRadius: '15px',
    marginTop: '15px',
    '&:hover': {
      backgroundColor: theme.colors.orangeHover,
    },
  },
  locationPhonesKey: {
    paddingRight: 5,
  },
  locationAddressBlock: {
    paddingRight: 10,
    display: 'flex',
    '&:hover $locationAddressText': {
      color: '#F36C3D',
    },
  },
  highlightText: {
    color: '#F36C3D',
  },
  locationAddressMarker: {
    paddingTop: 5,
    marginRight: 10,
  },
  locationDetail: {
    // marginTop: 15,
    '& a:first-child': {
      marginRight: 15,
    },
  },
  toggleBtn: {
    position: 'absolute',
    top: 0,
    right: 0,
    '& svg': {
      transform: 'rotate(90deg)',
      transition: `transform ${theme.animations.animate}`,
    },
  },
  toggleBtnActive: {
    '& svg': {
      transform: 'rotate(-90deg)',
    },
  },
}))

const LocationIndexCard = ({
  country,
  code,
  title,
  address,
  detailURL,
  detailText,
  numbers,
  hours,
  selectLocation,
  isActive,
}) => {
  const [showDays, setShowDays] = useBoolean()
  const [isCardHovered, setIsCardHovered] = useState(false)
  const classes = useStyles()

  const locationContext = useContext(LocationContext)

  return (
    <div className={classes.locationCard}>
      <h3 className={classes.locationTitle}>{title}</h3>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
        }}
      >
        <div
          className={`${classes.locationAddressBlock} ${isActive ? classes.highlightText : ''}`}
          onClick={selectLocation}
          onMouseEnter={() => setIsCardHovered(true)}
          onMouseLeave={() => setIsCardHovered(false)}
        >
          <div className={classes.locationAddressMarker}>
            <SVG
              name="Location"
              width={25}
              height={25}
              colorOne={isCardHovered || isActive ? '#F36C3D' : '#747474'}
            />
          </div>

          <div className={classes.locationAddressText}>{address}</div>
        </div>
        <div>
          {numbers
            .filter(number => number.key.toLowerCase() === 'ph')
            .map((number, index) => (
              <div
                key={`location-index-phones-${index}-${number.key}`}
                className={classes.locationPhoneHalf}
              >
                <div className={classes.locationPhonesKey}>
                  <SVG name="Phone" width={16} height={16} />
                </div>
                <div className={classes.locationPhonesValue}>
                  <p>
                    <a href={`tel:${number.value}`}>{number.value}</a>
                  </p>
                </div>
              </div>
            ))}

          <div className={classes.locationPhoneHalf}>
            <div className={classes.locationPhonesKey}>
              <SVG name="Clock" width={16} height={16} />
            </div>
            <div
              className={`${classes.locationPhonesValue} ${
                showDays ? classes.locationPhonesValueTimesShow : classes.locationPhonesValueTimes
              }`}
            >
              <span
                className={`${classes.toggleBtn} ${showDays ? classes.toggleBtnActive : ''}`}
                onClick={setShowDays.toggle}
              >
                <SVG name="Chevron" width={14} height={14} />
              </span>
              <div>
                {hours &&
                  hours.length > 0 &&
                  hours.map((hour, index) => {
                    const { day, opening_time, closing_time } = hour
                    return (
                      <p key={`location-index-hours-${index}-${day}`}>
                        <span>{capitalizeFirstLetter(day)}</span>
                        <span>
                          {opening_time} - {closing_time}
                        </span>
                      </p>
                    )
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={classes.locationDetail}>
        <a href={detailURL} className={classes.locationDetailLink}>
          {detailText}
        </a>
        <a
          href="#"
          onClick={e => {
            e.preventDefault()
            window.scrollTo(0, 0)
            locationContext.updateLocation(`${country}-${code}`, title)
            setTimeout(() => {
              locationContext.updateLocation(`${country}-${code}`, title)
            }, 100)
          }}
          className={classes.locationDetailLink}
        >
          Save for booking
        </a>
      </div>
    </div>
  )
}

LocationIndexCard.propTypes = {
  title: PropTypes.string,
  address: PropTypes.string,
  lat: PropTypes.number,
  lng: PropTypes.number,
  detailURL: PropTypes.string,
  detailText: PropTypes.string,
  numbers: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
}

export default LocationIndexCard
