import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
    st0: {
        fill: '#003967',
    },
    st1: {
        fill: '#F7C413',
    },
    st2: {
        fill: '#FFDD00',
    },
}))

function SVGMapMarker(props) {
    const classes = useStyles()

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            x="0px"
            y="0px"
            viewBox="0 0 64 64"
            height={props.height}
            width={props.width}
        >
            <g>
                <g>
                    <path
                        className={classes.st0}
                        d="M32,0C21,0,12,9,12,20c0,0,0,0.1,0,0.1c0,0.5,0,1.1,0.1,1.6c0,0.4,0.1,0.8,0.1,1.1c0,0.1,0,0.2,0,0.4    c0.1,0.4,0.1,0.8,0.2,1.2C15.5,39.7,32,64,32,64s16.5-24.3,19.5-39.6c0.1-0.4,0.1-0.8,0.2-1.2c0-0.1,0-0.2,0-0.4    c0-0.4,0.1-0.8,0.1-1.1c0-0.6,0.1-1.1,0.1-1.6C52,9,43.1,0,32,0C32,0,32,0,32,0z M32,29.5c-5.2,0-9.4-4.2-9.4-9.4    c0-5.2,4.2-9.4,9.4-9.4s9.4,4.2,9.4,9.4c0,0,0,0,0,0C41.4,25.3,37.2,29.5,32,29.5C32,29.5,32,29.5,32,29.5z"
                    ></path>
                    <circle className={classes.st0} cx="32.6" cy="20.3" r="10.7"></circle>
                </g>
            </g>
            <g>
                <g>
                    <path
                        className={classes.st1}
                        d="M34,23.1c-0.1-0.1-0.2-0.2-0.3-0.3c-0.3-0.3-0.6-0.6-0.9-0.9c-0.4-0.4-0.2-0.2-0.6-0.6    c-0.5-0.5-0.3-0.3-0.8-0.8c-0.5-0.5-1-1-1.5-1.5c-0.4-0.4-0.9-0.9-1.3-1.3c-0.3-0.3-0.6-0.6-1-1c-0.9-0.9-1.7-1.8-2.4-2.9    c-0.2-0.3-0.3-0.8-0.3-1.2c0-0.3,0.1-0.6,0.2-0.9c0.1-0.3,0.3-0.6,0.6-0.8l2.9-2.6l2.6,2.6l0,0l12.2,12.2L31.2,35.3l0,0l-2.6,2.6    l-2.9-2.6c-0.2-0.2-0.4-0.5-0.6-0.8c-0.1-0.3-0.2-0.6-0.2-0.9c0-0.4,0.1-0.8,0.3-1.2c0.7-1.1,1.5-2,2.4-2.9    C27.9,29.2,33.9,23.2,34,23.1z"
                    ></path>
                    <path
                        className={classes.st2}
                        d="M43.3,23.1L28.6,8.3c0,0-2.9,2.6-2.9,2.6c-0.5,0.4-0.8,1.1-0.8,1.7c0,0.4,0.1,0.8,0.3,1.2    c0.7,1.1,1.5,2,2.4,2.9c0.3,0.3,0.6,0.6,1,1c0.3,0.3,5.8,5.8,8.5,8.5c0,0,1.7,1.5-0.3,3.5L43.3,23.1z"
                    ></path>
                </g>
            </g>
        </svg>
    )
}

export default SVGMapMarker
