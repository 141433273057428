import { createContext } from 'react'

export const demystify = contextStr => {
  return JSON.parse(
    atob(
      atob(contextStr)
        .split('')
        .reverse()
        .join(''),
    )
      .split('')
      .reverse()
      .join(''),
  )
}

const Context = createContext({})

export default Context