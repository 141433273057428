import React from 'react'

function Menu(props) {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 30 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="2" fill={props.colorOne} />
      <rect y="16" width="30" height="2" fill={props.colorOne} />
      <rect y="8" width="30" height="2" fill={props.colorOne} />
    </svg>
  )
}

export default Menu
