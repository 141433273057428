import React from 'react'

function LeftQ(props) {
  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      viewBox="0 0 114 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.70031 80C3.07886 80 0 76.919 0 72.3037C0 66.1542 6.16398 64.6137 6.16398 64.6137C15.4069 61.5389 23.1072 53.8427 23.1072 46.1526C10.7855 43.0779 3.07888 33.8474 3.07888 23.3844C3.07888 10.771 13.8643 0 27.7287 0C41.593 0 52.3786 10.771 52.3786 30.7663C52.3786 56.9237 29.2713 80 7.70031 80ZM69.3218 80C64.7003 80 61.6214 76.919 61.6214 72.3037C61.6214 66.1542 67.7855 64.6137 67.7855 64.6137C77.0284 61.5389 84.7287 53.8427 84.7287 46.1526C72.4069 43.0779 64.7003 33.8474 64.7003 23.3844C64.7003 10.771 75.4858 0 89.3501 0C103.215 0 114 10.771 114 30.7663C114 56.9237 90.8864 80 69.3218 80Z"
        fill={props.colorOne}
      />
    </svg>
  )
}

export default LeftQ
