import React from 'react'

function Tick(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0.27 17 14.47"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M16.6 3.06L6.91 14.15c-.65.78-1.77.78-2.42 0L.4 9.38a1.86 1.86 0 01.1-2.43 1.67 1.67 0 012.33.1l2.88 3.4L14.17.84c.65-.68 1.68-.78 2.33-.1.65.58.65 1.75.1 2.33z"
            />
        </svg>
    )
}

export default Tick
