import initialState from './initialState'
import _ from 'lodash'

export default function appReducer(state = initialState.app, action) {
    switch (action.type) {
        case 'getRates.ok':
        case 'getPromoRates.ok':
            if (_.has(action.response.result.api.ResRates, 'Messages.Message')) {
                const msg = action.response.result.api.ResRates.Messages.Message
                return {
                    ...state,
                    messages: msg,
                }
            } else {
                return state
            }
        case 'api.ex':
            return {
                ...state,
                messages: action.error.Message,
            }
        case 'clear.ex':
            return {
                ...state,
                messages: [],
            }
        case 'worker':
            const worker = {
                ...state.worker,
                [action.data.work]: action.data.status,
            }
            return {
                ...state,
                worker,
            }
        default:
            return state
    }
}
