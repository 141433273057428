import React from 'react'

function RightQ(props) {
  return (
    <svg
      className={props.className}
      height={props.height}
      width={props.width}
      viewBox="0 0 114 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M44.6782 0C49.2997 0 52.3785 3.07474 52.3785 7.69001C52.3785 13.8458 46.2145 15.3863 46.2145 15.3863C36.9715 18.4611 29.2713 26.1511 29.2713 33.8474C41.5931 36.9221 49.2996 46.1526 49.2996 56.6093C49.2996 69.229 38.5141 80 24.6498 80C10.7854 80 0 69.229 0 49.2274C0 23.0763 23.1072 0 44.6782 0ZM106.3 0C110.921 0 114 3.07474 114 7.69001C114 13.8458 107.836 15.3863 107.836 15.3863C98.593 18.4611 90.8927 26.1511 90.8927 33.8474C103.215 36.9221 110.921 46.1526 110.921 56.6093C110.921 69.229 100.136 80 86.2712 80C72.4069 80 61.5962 69.229 61.5962 49.2274C61.5962 23.0763 84.7287 0 106.3 0Z"
        fill={props.colorOne}
      />
    </svg>
  )
}

export default RightQ
