import React from 'react'

function Wheel(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="4 4 44 44"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M28.77 42.22C28.9 33.24 35 26 42.5 26a16.5 16.5 0 01-13.73 16.22M9.5 26c7.51 0 13.6 7.24 13.73 16.22A16.5 16.5 0 019.5 26M26 28.75a2.75 2.75 0 110-5.5 2.75 2.75 0 010 5.5M26 9.5c7.16 0 13.2 4.61 15.49 11H10.51c2.28-6.39 8.33-11 15.49-11M26 4a22 22 0 100 44 22 22 0 000-44"
            />
        </svg>
    )
}

export default Wheel
