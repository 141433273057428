import React, { createContext, useState } from 'react'

// { value:'EDF3', label: 'Melbourne' }
export const LocationContext = createContext()

export const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null)

  const updateLocation = (value, label) => {
    setLocation({ value, label })
  }

  return (
    <LocationContext.Provider value={{ location, updateLocation }}>
      {children}
    </LocationContext.Provider>
  )
}
