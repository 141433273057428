import React from 'react'

import LeftQ from './SVGLeftQ'
import RightQ from './SVGRightQ'

import AceLogo from './SVGAceLogo'
import Address from './SVGAddress'
import Calendar from './SVGCalendar'
import Car from './SVGCar'
import Chevron from './SVGChevron'
import Child from './SVGChild'
import CircleEmpty from './SVGCircleEmpty'
import Clock from './SVGClock'
import Cross from './SVGCross'
import Details from './SVGDetails'
import Edit from './SVGEdit'
import Email from './SVGEmail'
import Facebook from './SVGFacebook'
import GPS from './SVGGPS'
import Info from './SVGInfo'
import Instagram from './SVGInstagram'
import Insurance from './SVGInsurance'
import Linkedin from './SVGLinkedin'
import LocationMap from './SVGLocationMap'
import Location from './SVGLocation'
import Luggage from './SVGLuggage'
import MapMarker from './SVGMapMarker'
import MapMarkerPlain from './SVGMapMarkerPlain'
import Menu from './SVGMenu'
import Minus from './SVGMinus'
import Money from './SVGMoney'
import Phone from './SVGPhone'
import Play from './SVGPlay'
import Plus from './SVGPlus'
import Rotate from './SVGRotate'
import Rental from './SVGRental'
import Search from './SVGSearch'
import SearchCar from './SVGSearchCar'
import Shuttle from './SVGShuttle'
import Speed from './SVGSpeed'
import Tick from './SVGTick'
import TickCircle from './SVGTickCircle'
import Twitter from './SVGTwitter'
import User from './SVGUser'
import Wheel from './SVGWheel'
import Wrench from './SVGWrench'
import Youtube from './SVGYoutube'

const SVG = ({ name, width, height, className, colorOne, colorTwo }) => {
  if (name === 'Youtube') {
    return <Youtube width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Minus') {
    return <Minus width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Plus') {
    return <Plus width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Linkedin') {
    return <Linkedin width={width} height={height} colorOne={colorOne} />
  } else if (name === 'LeftQ') {
    return <LeftQ width={width} height={height} colorOne={colorOne} />
  } else if (name === 'RightQ') {
    return <RightQ width={width} height={height} colorOne={colorOne} />
  } else if (name === 'AceLogo') {
    return <AceLogo width={width} height={height} />
  } else if (name === 'Address') {
    return <Address width={width} height={height} />
  } else if (name === 'Car') {
    return <Car width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Chevron') {
    return <Chevron className={className} width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Calendar') {
    return <Calendar width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Child') {
    return <Child width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Clock') {
    return <Clock width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Cross') {
    return <Cross width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Details') {
    return <Details width={width} height={height} />
  } else if (name === 'Email') {
    return <Email width={width} height={height} colorOne={colorOne} />
  } else if (name === 'GPS') {
    return <GPS width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Info') {
    return <Info width={width} height={height} colorOne={colorOne} />
  } else if (name === 'LocationMap') {
    return <LocationMap width={width} height={height} />
  } else if (name === 'Location') {
    return <Location width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Luggage') {
    return <Luggage width={width} height={height} colorOne={colorOne} />
  } else if (name === 'MapMarker') {
    return <MapMarker width={width} height={height} />
  } else if (name === 'MapMarkerPlain') {
    return <MapMarkerPlain width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Phone') {
    return <Phone width={width} height={height} colorOne={colorOne} />
  } else if (name === 'CircleEmpty') {
    return <CircleEmpty width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Search') {
    return <Search width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Wheel') {
    return <Wheel width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Tick') {
    return <Tick width={width} height={height} colorOne={colorOne} />
  } else if (name === 'TickCircle') {
    return <TickCircle width={width} height={height} colorOne={colorOne} colorTwo={colorTwo} />
  } else if (name === 'User') {
    return <User width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Instagram') {
    return <Instagram width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Facebook') {
    return <Facebook width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Twitter') {
    return <Twitter width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Wrench') {
    return <Wrench width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Money') {
    return <Money width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Speed') {
    return <Speed width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Menu') {
    return <Menu width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Shuttle') {
    return <Shuttle width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Insurance') {
    return <Insurance width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Play') {
    return <Play width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Rental') {
    return <Rental width={width} height={height} />
  } else if (name === 'Rotate') {
    return <Rotate width={width} height={height} colorOne={colorOne} />
  } else if (name === 'SearchCar') {
    return <SearchCar width={width} height={height} colorOne={colorOne} />
  } else if (name === 'Edit') {
    return <Edit width={width} height={height} colorOne={colorOne} />
  }
}

// SVG.propTypes = {
//   name: PropTypes.string,
//   width: function(props, propName, componentName) {
//     if (props[propName] === undefined) {
//       return new Error(
//         'Invalid prop `' + propName + '` supplied to' +
//         ' `' + componentName + '`. You must specify a width as either a number or string with units (e.g. 13px) - For SVGs to render in IE11 this is a REQUIREMENT.'
//       );
//     }
//     if (/auto/.test(props[propName])) {
//       return new Error(
//         'Invalid prop `' + propName + '` supplied to' +
//         ' `' + componentName + '`. You must specify a width as either a number or string with units (e.g. 13px) - For SVGs to render in IE11 this is a REQUIREMENT. Please do not use "auto".'
//       );
//     }
//   },
//   height:  function(props, propName, componentName) {
//     if (props[propName] === undefined) {
//       return new Error(
//         'Invalid prop `' + propName + '` supplied to' +
//         ' `' + componentName + '`. You must specify a height as either a number or string with units (e.g. 13px) - For SVGs to render in IE11 this is a REQUIREMENT.'
//       );
//     }
//     if (/auto/.test(props[propName])) {
//       return new Error(
//         'Invalid prop `' + propName + '` supplied to' +
//         ' `' + componentName + '`. You must specify a height as either a number or string with units (e.g. 13px) - For SVGs to render in IE11 this is a REQUIREMENT. Please do not use "auto".'
//       );
//     }
//   },
//   className: PropTypes.oneOfType([
//     PropTypes.string,
//     PropTypes.node,
//   ]),
//   colorOne: PropTypes.string,
//   colorTwo: PropTypes.string,
//   colorThree: PropTypes.string,
//   colorFour: PropTypes.string,
// }

export default SVG
