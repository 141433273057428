/* eslint-disable no-unused-vars */
import { put, call } from 'redux-saga/effects'
import _ from 'lodash'
// force update

export function makeRequest(request, returnType) {
  return request()
    .then(response => response[returnType]().then(result => ({ result, response })))
    .then(({ result, response }) => {
      if (!response.ok) {
        throw result
      }
      return result
    })
}

export function* callApiRates(request, action, callback = r => {}, returnType = 'json') {
  try {
    yield put({ type: 'worker', data: { work: action, status: 'busy' } })
    let response = yield call(makeRequest, request, returnType)

    if (response.result.api.hasOwnProperty('Errors')) {
      const skeleton = {
        result: {
          api: {
            '@attributes': {
              regardingReferenceNumber: 'df9c9442-6144-438a-a616-45801b33a128',
              version: '2.3200',
              webxg_id: 'cbf4947c-4672-11ec-98b8-fa4c69120c65',
            },
            ResRates: {
              '@attributes': { success: 'false' },
              Messages: {
                Message: [
                  {
                    '@attributes': {
                      number: response.result.api.Errors.Error['@attributes'].Code,
                      type: 'Error',
                    },
                    Text: response.result.api.Errors.Error['@attributes'].ShortText,
                  },
                ],
              },
            },
          },
        },
      }
      response = { ...response, ...skeleton }
    } else {
      let avaCarsCount = 0
      const vehList =
        response.result.api.VehAvailRSCore.VehVendorAvails.VehVendorAvail.VehAvails.VehAvail
      let vehListArray = []
      if (Array.isArray(vehList)) {
        avaCarsCount =
          response.result.api.VehAvailRSCore.VehVendorAvails.VehVendorAvail.VehAvails.VehAvail
            .length
        vehListArray = vehList
      } else {
        avaCarsCount = 1
        vehListArray.push(vehList)
      }

      const avaCars = []

      for (let i = 0; i < avaCarsCount; i++) {
        const car = vehListArray[i]

        avaCars.push({
          RateID: car.VehAvailCore.Reference['@attributes'].ID,
          Class: car.VehAvailCore.Vehicle.VehMakeModel['@attributes'].Code,
          Availability: car.VehAvailCore['@attributes'].Status,
          CurrencyCode: car.VehAvailCore.TotalCharge['@attributes'].CurrencyCode,
          Estimate: car.VehAvailCore.TotalCharge['@attributes'].EstimatedTotalAmount,
          RateOnlyEstimate: car.VehAvailCore.TotalCharge['@attributes'].RateTotalAmount,
          DropCharge: '0.00',
          Distance: { Included: 'unlimited' },
          AlternateRateProduct: [{}, {}],
          fullDetails: car,
          storeInfo: response.result.api.VehAvailRSCore.VehVendorAvails.VehVendorAvail,
        })
      }
      const skeleton = {
        result: {
          api: {
            '@attributes': {
              regardingReferenceNumber: 'df9c9442-6144-438a-a616-45801b33a128',
              version: '2.3200',
              webxg_id: 'cbf4947c-4672-11ec-98b8-fa4c69120c65',
            },
            ResRates: {
              '@attributes': { success: 'true' },
              Count: '',
              Rate: [],
            },
          },
        },
      }
      response = { ...response, ...skeleton }
      response.result.api.ResRates.Count = avaCarsCount
      response.result.api.ResRates.Rate = avaCars
    }
    const responseObject = _.get(response, 'reqdata.response', '')
    if (
      !_.get(response, `result.api.skiprequest`, false) &&
      _.get(response, `result.api.${responseObject}.@attributes.success`, 'false') == 'false'
    ) {
      const msgs = _.get(response, `result.api.${responseObject}.Messages`, '')
      yield put({ type: 'api.ex', error: msgs, response })
      yield put({ type: 'worker', data: { work: action, status: 'ready' } })
    } else {
      yield put({ type: action + '.ok', response })
      yield put({ type: 'worker', data: { work: action, status: 'ready' } })
      return response
    }
  } catch (error) {
    yield put({ type: action + '.ex', error: error })
    yield put({ type: 'worker', data: { work: action, status: 'ready' } })
    throw error
  }
}

export default function* callApi(request, action, callback = r => {}, returnType = 'json') {
  try {
    yield put({ type: 'worker', data: { work: action, status: 'busy' } })
    const response = yield call(makeRequest, request, returnType)
    const responseObject = _.get(response, 'reqdata.response', '')

    const errorMsg = _.get(
      response,
      `result.api.NewReservationResponse.Messages.Message[0].Text`,
      '',
    )

    if (responseObject === 'NewReservationResponse' && errorMsg !== '') {
      alert(`Error: ${errorMsg}`)
    }

    if (
      !_.get(response, `result.api.skiprequest`, false) &&
      _.get(response, `result.api.${responseObject}.@attributes.success`, 'false') == 'false'
    ) {
      const msgs = _.get(response, `result.api.${responseObject}.Messages`, '')
      yield put({ type: 'api.ex', error: msgs, response })
      yield put({ type: 'worker', data: { work: action, status: 'ready' } })
    } else {
      yield put({ type: action + '.ok', response })
      yield put({ type: 'worker', data: { work: action, status: 'ready' } })
      return response
    }
  } catch (error) {
    yield put({ type: action + '.ex', error: error })
    yield put({ type: 'worker', data: { work: action, status: 'ready' } })
    throw error
  }
}

export function* callModelData(request, action, callback = r => {}, returnType = 'json') {
  try {
    yield put({ type: 'worker', data: { work: action, status: 'busy' } })
    const response = yield call(makeRequest, request, returnType)

    yield put({ type: action + '.ok', response })
    yield put({ type: 'worker', data: { work: action, status: 'ready' } })
    return response
  } catch (error) {
    yield put({ type: action + '.ex', error: error })
    yield put({ type: 'worker', data: { work: action, status: 'error' } })
    throw error
  }
}
