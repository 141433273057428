// This file merely configures the store for hot reloading.
// This boilerplate file is likely to be the same for each project that uses Redux.
// With Redux, the actual stores are in /reducers.

import { createStore, compose, applyMiddleware } from 'redux'
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant'
import { createBrowserHistory } from 'history'
// 'routerMiddleware': the new way of storing route changes with redux middleware since rrV4.
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createRootReducer from '../reducers'

import createSagaMiddleware from 'redux-saga'
import rootSaga from '../sagas/rootSaga'

import { persistStore } from 'redux-persist'

export const history = createBrowserHistory({ basename: '/' })
const connectRouterHistory = connectRouter(history) // for HMR

const sagaMiddleware = createSagaMiddleware()

const configureStore = initialState => {
    const reactRouterMiddleware = routerMiddleware(history)
    const middlewares = [
        // Redux middleware that spits an error on you when you try to mutate your state either inside a dispatch or between dispatches.
        reduxImmutableStateInvariant(),

        sagaMiddleware,
        reactRouterMiddleware,
    ]

    const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose // add support for Redux dev tools
    const store = createStore(
        createRootReducer(history), // root reducer with router state
        initialState,
        composeEnhancers(applyMiddleware(...middlewares)),
    )

    sagaMiddleware.run(rootSaga)

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers
        module.hot.accept('../reducers', () => {
            const nextRootReducer = require('../reducers').default // eslint-disable-line global-require
            store.replaceReducer(connectRouterHistory(nextRootReducer))
        })
    }

    const persistor = persistStore(store)
    //if(location.pathname == '/' || location.pathname == '/checkin' || location.pathname.match(/(.+)experience\/(.+)/g)){
    if (location.pathname.indexOf('/bookings/') == -1) {
        persistor.purge()
    }

    return { store, persistor }
}

export default configureStore
