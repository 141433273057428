import React from 'react'

function Address(props) {
    return (
		<svg 
			viewBox="0 0 64 63" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg"
			height={props.height}
			width={props.width}
		>
			<path className="svg-fill" d="M31.5184 51.201C27.0025 51.197 22.5233 50.39 18.29 48.8177C16.9731 48.3337 15.8772 47.3873 15.2067 46.1549C14.5362 44.9225 14.3368 43.4882 14.6459 42.1197L16.1618 35.5092C16.6871 33.7458 17.8688 32.251 19.4634 31.3329C20.8496 30.6455 22.3959 30.3459 23.9385 30.4656L26.7081 30.4219H28.1657C28.3945 30.421 28.6182 30.4897 28.8071 30.6187C32.1306 32.9072 33.6028 31.1726 33.7632 30.9685C33.8702 30.8271 34.0093 30.7131 34.1689 30.6359C34.3286 30.5586 34.5042 30.5202 34.6815 30.5239L39.7834 30.7353C44.7103 30.7353 46.6927 33.8183 47.0717 35.4509L48.748 42.0104C49.0891 43.4186 48.8936 44.9033 48.1997 46.1752C47.5058 47.4471 46.3632 48.4151 44.9946 48.8906C40.6609 50.3997 36.1072 51.1804 31.5184 51.201ZM19.0407 46.7697C27.1381 49.7433 35.6217 49.7579 44.2438 46.8207C45.1032 46.5383 45.8233 45.9404 46.2589 45.1477C46.6945 44.355 46.8131 43.4265 46.5907 42.5497L44.8998 35.9173C44.8998 35.7716 44.12 32.8926 39.7105 32.8926L35.0532 32.7031C34.1058 33.5632 31.6641 35.0719 27.8378 32.5865H26.7445C25.8067 32.5865 24.8665 32.6011 23.9239 32.6303H23.8365C22.7004 32.5282 21.5574 32.7242 20.5203 33.1987C19.4268 33.8241 18.6134 34.8435 18.2463 36.0485L16.7449 42.608C16.5551 43.4586 16.6802 44.349 17.097 45.1143C17.5139 45.8796 18.1941 46.4677 19.0116 46.7697H19.0407Z"/>
			<path className="svg-fill" d="M31.5402 29.1976C30.3969 29.1955 29.2713 28.9154 28.2605 28.3813C28.127 28.3181 28.0077 28.2286 27.9098 28.1182C27.8119 28.0077 27.7374 27.8785 27.6907 27.7384C27.6441 27.5984 27.6263 27.4503 27.6385 27.3032C27.6506 27.1561 27.6924 27.0129 27.7614 26.8824C27.8303 26.7518 27.925 26.6366 28.0397 26.5437C28.1544 26.4507 28.2867 26.382 28.4287 26.3416C28.5707 26.3012 28.7194 26.2899 28.8658 26.3085C29.0123 26.3271 29.1534 26.3752 29.2808 26.4499C29.9546 26.7989 30.7 26.9872 31.4588 26.9998C32.2175 27.0125 32.9688 26.8492 33.6538 26.5228C33.7835 26.4606 33.9242 26.4245 34.0678 26.4166C34.2114 26.4088 34.3552 26.4293 34.4909 26.477C34.6266 26.5247 34.7516 26.5987 34.8587 26.6947C34.9658 26.7907 35.0529 26.9069 35.1151 27.0366C35.1773 27.1663 35.2134 27.307 35.2213 27.4506C35.2291 27.5942 35.2086 27.738 35.1609 27.8737C35.1131 28.0094 35.0392 28.1343 34.9432 28.2414C34.8472 28.3485 34.731 28.4357 34.6013 28.4979C33.6465 28.9581 32.6002 29.1973 31.5402 29.1976Z"/>
			<path className="svg-fill" d="M34.1272 28.5268C33.8957 28.529 33.6705 28.4518 33.489 28.3082C33.3075 28.1646 33.1806 27.9632 33.1295 27.7375C33.0784 27.5117 33.1061 27.2753 33.2081 27.0675C33.31 26.8597 33.4801 26.6931 33.6899 26.5954C34.5312 26.1868 35.2417 25.5514 35.7414 24.7607C36.241 23.9701 36.5099 23.0556 36.5178 22.1203V18.3741C36.4685 17.0878 35.9229 15.8706 34.9954 14.9779C34.0679 14.0853 32.8307 13.5866 31.5435 13.5866C30.2562 13.5866 29.019 14.0853 28.0915 14.9779C27.164 15.8706 26.6184 17.0878 26.5692 18.3741V22.1203C26.5764 23.0233 26.8292 23.9073 27.3004 24.6776C27.7716 25.4479 28.4434 26.0756 29.244 26.4933C29.3615 26.5547 29.4658 26.6388 29.5507 26.7406C29.6357 26.8425 29.6996 26.9602 29.7389 27.0868C29.7782 27.2135 29.792 27.3467 29.7796 27.4788C29.7673 27.6108 29.7289 27.7391 29.6667 27.8563C29.5411 28.0941 29.3267 28.2726 29.0701 28.3531C28.8135 28.4336 28.5355 28.4096 28.2965 28.2863C27.1657 27.6938 26.2183 26.8035 25.5567 25.7116C24.8952 24.6197 24.5446 23.3678 24.543 22.0912V18.3449C24.5449 16.4899 25.2827 14.7113 26.5945 13.3996C27.9062 12.0879 29.6847 11.3501 31.5398 11.3481C33.3938 11.3558 35.1698 12.0952 36.4814 13.4055C37.7931 14.7158 38.5343 16.491 38.5439 18.3449V22.0912C38.5362 23.408 38.16 24.6964 37.458 25.8106C36.7561 26.9247 35.7562 27.8202 34.5717 28.3956C34.4349 28.4713 34.2832 28.516 34.1272 28.5268Z"/>
			<path className="svg-fill" d="M28.8574 32.8634C28.7148 32.8644 28.5734 32.8372 28.4412 32.7835C28.3091 32.7299 28.1889 32.6507 28.0874 32.5505C27.9858 32.4503 27.905 32.3312 27.8496 32.1998C27.7941 32.0684 27.7651 31.9273 27.7641 31.7847L27.7131 27.8564C27.7102 27.7122 27.7359 27.5688 27.7886 27.4346C27.8413 27.3004 27.9201 27.1779 28.0204 27.0743C28.1206 26.9706 28.2404 26.8878 28.3728 26.8307C28.5052 26.7735 28.6476 26.7431 28.7918 26.7412C29.0792 26.7412 29.3551 26.8544 29.5597 27.0563C29.7643 27.2581 29.8812 27.5325 29.885 27.8199L29.936 31.7483C29.938 31.8919 29.9116 32.0344 29.8584 32.1678C29.8053 32.3012 29.7263 32.4228 29.6262 32.5257C29.526 32.6285 29.4066 32.7107 29.2747 32.7674C29.1427 32.8241 29.001 32.8542 28.8574 32.8561V32.8634Z"/>
			<path className="svg-fill" d="M34.1491 32.8637C34.0055 32.8618 33.8637 32.8316 33.7318 32.7749C33.5999 32.7182 33.4805 32.6361 33.3803 32.5332C33.2801 32.4303 33.2012 32.3087 33.148 32.1754C33.0949 32.042 33.0685 31.8994 33.0704 31.7559L33.1214 27.8274C33.1253 27.54 33.2421 27.2657 33.4468 27.0638C33.6514 26.8619 33.9272 26.7488 34.2147 26.7488C34.3583 26.7507 34.5 26.7808 34.632 26.8375C34.7639 26.8942 34.8833 26.9764 34.9835 27.0793C35.0837 27.1821 35.1626 27.3037 35.2158 27.4371C35.2689 27.5705 35.2953 27.7131 35.2933 27.8566L35.2424 31.785C35.2414 31.9276 35.2124 32.0687 35.1569 32.2C35.1015 32.3314 35.0207 32.4506 34.9191 32.5508C34.8176 32.6509 34.6973 32.7301 34.5652 32.7838C34.4331 32.8375 34.2917 32.8647 34.1491 32.8637Z"/>
			<path className="svg-fill" d="M37.1671 19.9631C34.7328 19.9631 32.3786 18.3378 31.7445 17.8641C29.8001 18.6936 27.7359 19.2078 25.6296 19.3873C25.361 19.4067 25.0956 19.3185 24.8919 19.1422C24.6882 18.9659 24.563 18.7158 24.5436 18.4471C24.5243 18.1785 24.6125 17.9131 24.7888 17.7095C24.9652 17.5058 25.2152 17.3805 25.4839 17.3612C25.5349 17.3612 30.3306 16.9968 32.5973 14.9415C32.7848 14.7482 33.0414 14.6373 33.3107 14.6332C33.5799 14.6291 33.8398 14.7321 34.0331 14.9196C34.2264 15.1071 34.3373 15.3637 34.3414 15.633C34.3455 15.9022 34.2425 16.1621 34.055 16.3554L33.7197 16.7489C34.773 17.4544 35.9961 17.8647 37.2618 17.937C37.5305 17.9302 37.7909 18.0304 37.9857 18.2157C38.1804 18.4009 38.2936 18.6559 38.3004 18.9246C38.3072 19.1932 38.207 19.4536 38.0217 19.6484C37.8365 19.8431 37.5815 19.9564 37.3128 19.9631H37.1671Z"/>
		</svg>
    )
}

export default Address