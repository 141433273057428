import React, { useState, useEffect } from 'react'
import axios from 'axios'
import GoogleMapReact from 'google-map-react'
import { createUseStyles } from 'react-jss'
import _ from 'lodash'
import FormInput from '../Form/FormInput'
import LocationIndexCard from './LocationIndexCard'
import SVG from '../SVG/SVG'
import './LocationIndex.css'

const useStyles = createUseStyles(theme => ({
  locationWrapper: {
    display: 'flex',
    '@media (max-width: 1024px)': {
      flexDirection: 'column-reverse',
    },
  },
  locationMap: {
    // ...theme.wrappers.large,
    fontFamily: theme.fontFamily,
    width: '100%',
    height: 357,
    overflow: 'hidden',
  },
  locationList: {
    boxSizing: 'border-box',
    padding: '0 30px 30px 30px',
    width: '27%',
    minWidth: 520,
    display: 'flex',
    flexDirection: 'column',
    height: '70vh', // Subtract menu height here
    overflowY: 'scroll',
    '@media (max-width: 1024px)': {
      width: '100%',
      minWidth: 280,
      height: 'auto',
    },
  },
  locationDividerTitle: {
    fontFamily: theme.fontFamily,
    color: '#000000',
    lineHeight: '32px',
    letterSpacing: '1-0.01em',
    fontWeight: 700,
    fontSize: 26,
    marginTop: 75,
    marginBottom: 26,
  },
  mapContainer: {
    height: '70vh',
    display: 'flex',
    width: '100%',
  },
  toggleButton: {
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    '& > span:nth-of-type(2)': {
      backgroundColor: '#F0F0F0',
      boxShadow: '0px 2px 2px 1px rgba(0, 0, 0, 0.12)',
      borderRadius: '50%',
      width: '30px',
      height: '28px',
      paddingTop: '2px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: -7,
      fill: theme.colors.main,
      color: theme.colors.main,
      '&:hover': {
        fill: theme.colors.white,
        color: theme.colors.white,
        backgroundColor: theme.colors.main,
      },
      '&:active, &:focus': {
        backgroundColor: theme.colors.mainHover,
      },
    },
    '& > span:nth-of-type(2) svg': {
      transform: 'rotate(90deg)',
      transition: `transform ${theme.animations.animate}`,
    },
  },
  toggleButtonActive: {
    borderBottomRightRadius: 0,
    borderBottomLeftRadius: 0,
    '& > span:nth-of-type(2) svg': {
      transform: 'rotate(-90deg)',
    },
    '@media (max-width: 899px)': {
      marginBottom: -2, // BUGFIX: White Line on iPhones.
    },
  },
  statesList: {
    marginTop: 15,
    '& ul': {
      margin: 0,
      padding: 0,
      listStyle: 'none',
      '& li': {
        fontSize: 16,
        lineHeight: 1.5,
        marginBottom: 12,
        '& a': {
          color: '#000',
          textDecoration: 'none',
          letterSpacing: '0.02em',
          '&:hover': {
            color: theme.colors.blue,
            textDecoration: 'none',
          },
          '& span': {
            fontWeight: '300',
          },
        },
      },
    },
  },
}))

export const LocationIndex = ({ children }) => {
  const mapLocalstorageKey = 'mapLocations6'
  const [country, setCountry] = useState('au')
  const [locations, setLocations] = useState([])
  const [locationsChange, setLocationsChange] = useState([])
  const [cluster, setcluster] = useState([])
  const [mapready, setmapready] = useState(false)
  const [displayStates, setdisplayStates] = useState(true)
  const [zoomLevel, setzoomLevel] = useState(5)
  const [mapLocation, setMapLocation] = useState({ lat: -28.464535, lng: 132.4652026 })
  const [locationSearch, setlocationSearch] = useState('')

  const [MarkerCluster, setMarkerCluster] = useState(null)
  const [tempAllTooltips, setTempAllTooltips] = useState({})
  const [permMap, setPermMap] = useState(null)

  const [openTooltips, setOpenTooltips] = useState({})
  const [selectedMarkerIdStateful, setSelectedMarkerIdStateful] = useState(null)
  const [mouseOverTooltipStateful, setMouseOverTooltipStateful] = useState(false)

  const classes = useStyles()

  const states = {
    nt: { lat: -20.078519422017578, lng: 133.60022695960194 },
    qld: { lat: -23.64581109085118, lng: 145.20787745265028 },
    vic: { lat: -37.050251027751685, lng: 144.75394633048893 },
    sa: { lat: -31.99842586629279, lng: 136.7036696403582 },
    nsw: { lat: -33.66720915445678, lng: 149.77517376903097 },
    wa: { lat: -26.92307425779037, lng: 122.14895334106113 },
    tas: { lat: -42.09334537356518, lng: 146.52910360210132 },
    act: { lat: -35.39817062252588, lng: 149.039440830571 },
  }

  const islands = {
    north: { lat: -38.4193055, lng: 175.8118657 },
    south: { lat: -44.1115846, lng: 170.3000912 },
  }

  useEffect(() => {
    setCountry(window.location.hostname.includes('.nz') ? 'nz' : 'au')
    setzoomLevel(window.location.hostname.includes('.nz') ? 6 : 5)
    setMapLocation(
      window.location.hostname.includes('.nz')
        ? { lat: -41.0547815, lng: 173.4115478 }
        : { lat: -28.464535, lng: 132.4652026 },
    )

    const mapLocations = localStorage.getItem(mapLocalstorageKey)
    if (mapLocations === null) {
      axios.get(`/cms/map?${Math.random()}`).then(({ data }) => {
        const clustertwo = data.filter(
          location => location.country === (window.location.hostname.includes('.nz') ? 'nz' : 'au'),
        )
        setLocations(clustertwo)
        setLocationsChange(clustertwo)
        setcluster(clustertwo)
        setmapready(true)
        localStorage.setItem(mapLocalstorageKey, JSON.stringify(clustertwo))
      })
    } else {
      const mapLocs = JSON.parse(mapLocations)
      setLocations(mapLocs)
      setLocationsChange(mapLocs)
      setcluster(mapLocs)
      setmapready(true)

      axios.get(`/cms/map?${Math.random()}`).then(({ data }) => {
        const clustertwo = data.filter(
          location => location.country === (window.location.hostname.includes('.nz') ? 'nz' : 'au'),
        )
        setLocations(clustertwo)
        setLocationsChange(clustertwo)
        localStorage.setItem(mapLocalstorageKey, JSON.stringify(clustertwo))
      })
    }
  }, [])

  const setGoogleMapRef = (map, maps) => {
    setPermMap(map)
    let markers = cluster.map(location => {
      const marker = new maps.Marker({
        position: {
          lat: parseFloat(location.gs_location_latitude),
          lng: parseFloat(location.gs_location_longitude),
        },
        url: location.url,
        label: {
          text: ' ',
          color: '#fff',
          fontSize: '15px',
          fontWeight: 'bold',
          className: 'markerlabel',
        },
        title: location.label,
        icon: '/themes/thrifty/assets/images/2.png',
        id: location.id,
      })

      const markerTooltip = new maps.InfoWindow({
        content: `<div id="${location.id}">${location.name}</div>`,
      })
      setTempAllTooltips(prev => ({ ...prev, [location.id]: markerTooltip }))

      maps.event.addListener(marker, 'mouseover', () => {
        markerTooltip.open(map, marker)
        setOpenTooltips(prev => {
          return { ...prev, [location.id]: markerTooltip }
        })

        let tooltipElement = document.getElementById(location.id).parentNode.parentNode.parentNode
          .parentNode
        tooltipElement.addEventListener('mouseenter', function() {
          setMouseOverTooltipStateful(true)
        })
        tooltipElement.addEventListener('mouseleave', function() {
          setMouseOverTooltipStateful(false)

          let currentSelectedMarkerId = null
          setSelectedMarkerIdStateful(prev => {
            currentSelectedMarkerId = prev
            return prev
          })

          if (currentSelectedMarkerId !== location.id) {
            markerTooltip.close()
            setOpenTooltips(prev => {
              return _.omit(prev, location.id)
            })
          }
        })
        tooltipElement.addEventListener('click', function() {
          let tempOpenTooltips = {}
          setOpenTooltips(prev => {
            tempOpenTooltips = prev
            return prev
          })
          Object.entries(tempOpenTooltips).map(toolTipArray => {
            if (toolTipArray[0] !== location.id.toString()) {
              toolTipArray[1]?.close()
            }
          })
          setOpenTooltips({ [location.id]: markerTooltip })
          selectMarker(location)
          setSelectedMarkerIdStateful(location.id)
        })
      })
      maps.event.addListener(marker, 'mouseout', () => {
        let currentSelectedMarkerId = null
        setSelectedMarkerIdStateful(prev => {
          currentSelectedMarkerId = prev
          return prev
        })
        setTimeout(function() {
          let currentMouseOverTooltip = null
          setMouseOverTooltipStateful(prev => {
            currentMouseOverTooltip = prev
            return prev
          })

          if (!currentMouseOverTooltip && currentSelectedMarkerId !== location.id) {
            markerTooltip.close()
            setOpenTooltips(prev => {
              return _.omit(prev, location.id)
            })
          }
        }, 500)
      })

      maps.event.addListener(marker, 'click', function() {
        let currentSelectedMarkerId = null
        setSelectedMarkerIdStateful(prev => {
          currentSelectedMarkerId = prev
          return prev
        })
        if (currentSelectedMarkerId === location.id) {
          markerTooltip.close()
          setOpenTooltips({})
          setSelectedMarkerIdStateful(null)
        } else {
          let tempOpenTooltips = {}
          setOpenTooltips(prev => {
            tempOpenTooltips = prev
            return prev
          })
          Object.entries(tempOpenTooltips).map(toolTipArray => {
            toolTipArray[1]?.close()
          })
          setOpenTooltips({})

          markerTooltip.open(map, marker)
          setOpenTooltips(prev => ({ ...prev, [location.id]: markerTooltip }))

          selectMarker(location)
          setSelectedMarkerIdStateful(location.id)
        }
      })

      maps.event.addListener(map, 'zoom_changed', function() {
        setTimeout(function() {
          const cnt = map.getCenter()
          cnt.e += 0.000001
          map.panTo(cnt)
          cnt.e -= 0.000001
          map.panTo(cnt)
        }, 400)
      })

      google.maps.event.addListener(map, 'idle', function() {
        const cnt = map.getCenter()
        cnt.e += 0.000001
        map.panTo(cnt)
        cnt.e -= 0.000001
        map.panTo(cnt)
      })

      return marker
    })

    const markersCluster = new MarkerClusterer(map, markers, {
      minimumClusterSize: 2,
      gridSize: 40,
      styles: [
        {
          height: 46,
          url: '/themes/thrifty/assets/images/2.png',
          width: 47,
          textSize: 15,
          textColor: '#fff',
          anchor: [12],
        },
      ],
    })
    setMarkerCluster(markersCluster)
  }

  const selectMarker = selectedLocation => {
    const loc = locations.filter(loc => loc.code === selectedLocation.code.toUpperCase())
    if (loc[0].gs_location_latitude.length && loc[0].gs_location_longitude.length) {
      const lat = parseFloat(loc[0].gs_location_latitude)
      const long = parseFloat(loc[0].gs_location_longitude)
      setLocationsChange(loc)
      setMapLocation({
        lat: lat,
        lng: long,
      })
      setzoomLevel(14)
      setdisplayStates(false)
    }
  }

  const selectLocation = code => {
    const loc = locations.filter(loc => loc.code === code.toUpperCase())
    if (loc[0].gs_location_latitude.length && loc[0].gs_location_longitude.length) {
      const lat = parseFloat(loc[0].gs_location_latitude)
      const long = parseFloat(loc[0].gs_location_longitude)
      setMapLocation({
        lat: lat,
        lng: long,
      })
      setzoomLevel(14)
      if (MarkerCluster?.markers_.length) {
        const selectedLocMarker = MarkerCluster.markers_.filter(({ id }) => {
          return id === loc[0].id
        })
        setSelectedMarkerIdStateful(null)
        setOpenTooltips(prev => {
          Object.entries(prev).map(toolTipArray => {
            toolTipArray[1]?.close()
          })
          return {}
        })

        setMouseOverTooltipStateful(false)
        setOpenTooltips({})

        if (tempAllTooltips?.[loc[0].id]) {
          tempAllTooltips[loc[0].id].open(permMap, selectedLocMarker[0])
          setOpenTooltips({ [loc[0].id]: tempAllTooltips[loc[0].id] })
          setSelectedMarkerIdStateful(loc[0].id)
        }
      }
    }
  }

  const selectState = state => {
    const locs = locations.filter(location => location.gs_region === state.toUpperCase())
    setLocationsChange(locs)
    setzoomLevel(6)
    setMapLocation(states[state.toLowerCase()])
    setdisplayStates(false)
  }

  const selectIsland = island => {
    const locs = locations.filter(
      location => location.island.toLowerCase() === island.toLowerCase(),
    )
    setLocationsChange(locs)
    setzoomLevel(7)
    setMapLocation(islands[island.toLowerCase()])
    setdisplayStates(false)
  }

  const locationSearchFn = el => {
    const text = el.currentTarget.value
    setLocationsChange(locations)
    if (text.trim().length > 0) {
      setlocationSearch(text.toLowerCase())
      setdisplayStates(false)
    } else {
      setlocationSearch('')
      setdisplayStates(true)
    }
  }

  return (
    <div className={classes.location}>
      <div className={classes.locationWrapper}>
        <div className={classes.locationList}>
          <div className={classes.locationDividerTitle}>Thrifty locations</div>

          <div style={{ position: 'relative' }}>
            <FormInput
              greyBackground
              labelText="Search by Address, Postcode or Airport"
              inputType="text"
              defaultValue=""
              onChange={locationSearchFn}
            />
            <div style={{ position: 'absolute', top: 17, right: 17 }}>
              <SVG name="Search" width={16} height={16} colorOne="#007AC3" />
            </div>
          </div>

          {country === 'au' ? (
            <>
              <div
                className={
                  displayStates
                    ? `${classes.toggleButton} ${classes.toggleButtonActive}`
                    : classes.toggleButton
                }
                style={{ fontWeight: 'bold', fontSize: 16, lineHeight: '20px', marginTop: 35 }}
                onClick={() => setdisplayStates(!displayStates)}
              >
                <span>Search by state</span>
                <span>
                  <SVG name={'Chevron'} width={12} height={12} />
                </span>
              </div>
              {displayStates && (
                <div className={classes.statesList}>
                  <ul>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectState('ACT')
                        }}
                      >
                        Australian Capital Territory <span>(ACT)</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectState('NSW')
                        }}
                      >
                        New South Wales <span>(NSW)</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectState('NT')
                        }}
                      >
                        Northern Territory <span>(NT)</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectState('QLD')
                        }}
                      >
                        Queensland <span>(QLD)</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectState('SA')
                        }}
                      >
                        South Australia <span>(SA)</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectState('TAS')
                        }}
                      >
                        Tasmania <span>(TAS)</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectState('VIC')
                        }}
                      >
                        Victoria <span>(VIC)</span>
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectState('WA')
                        }}
                      >
                        Western Australia <span>(WA)</span>
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </>
          ) : (
            <>
              <div
                className={
                  displayStates
                    ? `${classes.toggleButton} ${classes.toggleButtonActive}`
                    : classes.toggleButton
                }
                style={{ fontWeight: 'bold', fontSize: 16, lineHeight: '20px', marginTop: 35 }}
                onClick={() => setdisplayStates(!displayStates)}
              >
                <span>Search by island</span>
                <span>
                  <SVG name={'Chevron'} width={12} height={12} />
                </span>
              </div>
              {displayStates && (
                <div className={classes.statesList}>
                  <ul>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectIsland('north')
                        }}
                      >
                        North Island
                      </a>
                    </li>
                    <li>
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault()
                          selectIsland('south')
                        }}
                      >
                        South Island
                      </a>
                    </li>
                  </ul>
                </div>
              )}
            </>
          )}

          {locationSearch !== '' &&
            locationsChange
              .filter(({ name, gs_region, gs_street_address, gs_postcode, gs_city }) => {
                return `${name} ${gs_region} ${gs_street_address} ${gs_postcode} ${gs_city}`
                  .toLowerCase()
                  .includes(locationSearch)
              })
              .sort(function(a, b) {
                return ('' + a.label).localeCompare(b.label)
              })
              .map((location, index) => (
                <LocationIndexCard
                  key={`location-index-card-${index}-${location.id}`}
                  id={location.id}
                  code={location.code}
                  country={location.country}
                  title={location.label}
                  address={location.map_address}
                  selectLocation={() => selectLocation(location.code)}
                  isActive={location.id === selectedMarkerIdStateful}
                  numbers={[
                    {
                      key: 'TF',
                      value: location.toll_free,
                    },
                    {
                      key: 'PH',
                      value: location.phone,
                    },
                  ]}
                  hours={location.hours}
                  detailURL={location.url}
                  detailText={'View Details'}
                  lat={Number(location.gs_location_latitude)}
                  lng={Number(location.gs_location_longitude)}
                />
              ))}

          {locations.length !== locationsChange.length &&
            locationsChange
              .sort(function(a, b) {
                return ('' + a.label).localeCompare(b.label)
              })
              .map((location, index) => (
                <LocationIndexCard
                  key={`location-index-card-${index}-${location.id}`}
                  id={location.id}
                  code={location.code}
                  country={location.country}
                  title={location.label}
                  address={location.map_address}
                  selectLocation={() => selectLocation(location.code)}
                  isActive={location.id === selectedMarkerIdStateful}
                  numbers={[
                    {
                      key: 'TF',
                      value: location.toll_free,
                    },
                    {
                      key: 'PH',
                      value: location.phone,
                    },
                  ]}
                  hours={location.hours}
                  detailURL={location.url}
                  detailText={'View Details'}
                  lat={Number(location.gs_location_latitude)}
                  lng={Number(location.gs_location_longitude)}
                />
              ))}
        </div>
        <div className={classes.mapContainer}>
          <div style={{ height: '70vh', width: '100%' }}>
            {mapready && (
              <GoogleMapReact
                bootstrapURLKeys={{ key: 'AIzaSyBaoBjA9GKJ21xmg28FYipyqp8NgWlBnrg' }}
                center={mapLocation}
                zoom={zoomLevel}
                options={{
                  styles: [
                    {
                      featureType: 'administrative',
                      elementType: 'labels.text.fill',
                      stylers: [{ color: '#505050' }],
                    },
                    {
                      featureType: 'landscape',
                      elementType: 'all',
                      stylers: [{ color: '#f2f2f2' }],
                    },
                    { featureType: 'poi', elementType: 'all', stylers: [{ visibility: 'off' }] },
                    {
                      featureType: 'road',
                      elementType: 'all',
                      stylers: [{ saturation: -100 }, { lightness: 45 }],
                    },
                    {
                      featureType: 'road.highway',
                      elementType: 'all',
                      stylers: [{ visibility: 'simplified' }],
                    },
                    {
                      featureType: 'road.arterial',
                      elementType: 'labels.icon',
                      stylers: [{ visibility: 'off' }],
                    },
                    {
                      featureType: 'transit',
                      elementType: 'all',
                      stylers: [{ visibility: 'off' }],
                    },
                    {
                      featureType: 'water',
                      elementType: 'all',
                      stylers: [{ color: '#93d0f1' }, { visibility: 'on' }],
                    },
                  ],
                }}
                onGoogleApiLoaded={({ map, maps }) => setGoogleMapRef(map, maps)}
              ></GoogleMapReact>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default LocationIndex
