import React from 'react'

function Insurance(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            viewBox="0 0 52 52"
            height={props.height}
            width={props.width}
            fill={props.colorOne}
        >
            <g>
                <path d="M43.1942368,15.0203066c-6.0499878-1.2600098-11.5199585-4.3800049-15.8199463-9.0100098l-0.6400146-0.6900024   c-0.1900024-0.1999512-0.4500122-0.3200073-0.7300415-0.3200073c-0.2999878-0.0099487-0.539978,0.1100464-0.7299805,0.3099976   l-0.9799805,1.0300293c-4.0800171,4.2700195-9.2200317,7.2199707-14.8599854,8.539978l-0.6500244,0.1500244   c-0.4400024,0.0999756-0.7600098,0.4899902-0.7700195,0.9500122C7.6742778,28.3903008,14.644249,37.5102959,18.8242416,41.8303032   c2.4800415,2.5599976,5.7300415,5.1600342,7.1700439,5.1699829h0.0299683c1.5599976-0.0299683,4.8400269-2.75,7.2200317-5.2599487   c3.3599854-3.5400391,11.1199951-13.1199951,10.7399902-25.7700195   C43.9742661,15.5102968,43.644249,15.1203432,43.1942368,15.0203066z M22.9018784,34.3198051l-4.6098633-4.6098633   l1.4140625-1.4140625l3.3901367,3.3901367l12.8007813-9.4863281l1.1904297,1.6064453L22.9018784,34.3198051z"></path>
            </g>
        </svg>
    )
}

export default Insurance
