import React from 'react'

function SVGMapMarkerPlain(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0.5 0.5 38 50"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M32.94 5.63A19.72 19.72 0 0019.5.5C14.43.5 9.65 2.32 6.07 5.63c-6.65 6.12-7.47 17.63-1.8 24.61L19.5 50.5l15.2-20.23c5.7-7 4.88-18.52-1.76-24.64zM19.68 24.24c-3.83 0-6.94-2.87-6.94-6.4 0-3.51 3.11-6.38 6.94-6.38s6.93 2.86 6.93 6.39c0 3.52-3.1 6.4-6.93 6.4z"
            />
        </svg>
    )
}

export default SVGMapMarkerPlain
