/* eslint-disable import/default */
import React, { lazy, Suspense } from 'react'
import { ThemeProvider } from 'react-jss'
import { hydrate } from 'react-dom'

import { theme } from '../components/ui/theme'
import { demystify } from './Context'

const ssr = document.querySelectorAll('.ssr')

export const Rehydrate = () => {
  if (ssr) {
    const context = demystify(data)
    const nodes = document.querySelectorAll('div.iso-rehydrate')
    for (var i = 0; i < nodes.length; i++) {
      const SSRComponent = lazy(() =>
        import(`../${nodes[i].dataset.path}/${nodes[i].dataset.component}`),
      )
      hydrate(
        <ThemeProvider theme={theme}>
          <Suspense
            fallback={
              <div
                dangerouslySetInnerHTML={{
                  __html: nodes[i].querySelector('div').innerHTML,
                }}
              ></div>
            }
          >
            <SSRComponent {...context[nodes[i].dataset.component]} />
          </Suspense>
        </ThemeProvider>,
        nodes[i],
      )
    }
  }
}