import React from 'react'

function Search(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 26 28"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M25.6 25.5l-6.4-6.93a11.54 11.54 0 002.55-7.26C21.75 5.07 16.87 0 10.87 0S0 5.07 0 11.3s4.88 11.3 10.87 11.3c2.25 0 4.4-.7 6.23-2.04l6.46 6.99a1.38 1.38 0 002 .04c.57-.57.59-1.5.04-2.09zM10.87 2.95c4.44 0 8.04 3.75 8.04 8.36 0 4.6-3.6 8.35-8.04 8.35s-8.03-3.75-8.03-8.35c0-4.61 3.6-8.36 8.03-8.36z"
            />
        </svg>
    )
}

export default Search
