import React from 'react'
import { createUseStyles } from 'react-jss'

const useStyles = createUseStyles(theme => ({
  cls1: {
    '&:hover': {
      fill: '#F36C3D',
    },
  },
}))

function Location(props) {
  const classes = useStyles()
  return (
    <svg
      className={classes.cls1}
      height={props.height}
      width={props.width}
      fill={props.colorOne}
      viewBox="0 0 700 700"
      xmlns="http://www.w3.org/2000/svg"
    >
      <defs>
        <symbol id="a" overflow="visible">
          <path d="M29.312-1.75A20.895 20.895 0 0 1 24.578.016c-1.637.406-3.34.609-5.11.609-5.312 0-9.527-1.484-12.64-4.453-3.106-2.969-4.657-7-4.657-12.094s1.55-9.125 4.656-12.094c3.113-2.969 7.328-4.453 12.641-4.453 1.77 0 3.473.199 5.11.594 1.644.398 3.222.992 4.734 1.78v6.595c-1.531-1.04-3.04-1.801-4.516-2.282a14.826 14.826 0 0 0-4.671-.734c-2.938 0-5.247.945-6.922 2.828-1.68 1.875-2.516 4.465-2.516 7.766 0 3.293.836 5.882 2.516 7.765 1.675 1.875 3.984 2.813 6.922 2.813 1.632 0 3.19-.239 4.671-.719 1.477-.488 2.985-1.254 4.516-2.297z" />
        </symbol>
        <symbol id="b" overflow="visible">
          <path d="M21.453-17.406c-.68-.313-1.352-.54-2.016-.688a8.556 8.556 0 0 0-1.984-.234c-1.969 0-3.484.633-4.547 1.89-1.055 1.262-1.578 3.071-1.578 5.423V0H3.672v-23.922h7.656V-20c.977-1.563 2.102-2.704 3.375-3.422 1.281-.72 2.813-1.078 4.594-1.078.25 0 .523.011.828.03.3.024.734.071 1.297.141z" />
        </symbol>
        <symbol id="c" overflow="visible">
          <path d="M27.562-12.031v2.188H9.671c.188 1.792.832 3.136 1.938 4.03 1.113.9 2.671 1.344 4.671 1.344 1.602 0 3.25-.234 4.938-.703 1.687-.476 3.422-1.203 5.203-2.172v5.891A31.913 31.913 0 0 1 20.999.094a28.432 28.432 0 0 1-5.422.531c-4.336 0-7.703-1.097-10.109-3.297-2.398-2.207-3.594-5.297-3.594-9.265 0-3.906 1.176-6.973 3.532-9.203 2.363-2.239 5.609-3.36 9.734-3.36 3.758 0 6.77 1.137 9.031 3.406 2.258 2.262 3.39 5.282 3.39 9.063zm-7.86-2.531c0-1.457-.43-2.63-1.28-3.516-.844-.894-1.95-1.344-3.313-1.344-1.492 0-2.7.418-3.625 1.25-.918.836-1.492 2.04-1.719 3.61z" />
        </symbol>
        <symbol id="d" overflow="visible">
          <path d="M14.391-10.766c-1.594 0-2.793.273-3.594.813-.804.542-1.203 1.34-1.203 2.39 0 .969.32 1.73.969 2.281.644.543 1.547.813 2.703.813 1.437 0 2.644-.516 3.625-1.547.988-1.031 1.484-2.32 1.484-3.875v-.875zm11.688-2.89V-.002h-7.703v-3.547c-1.031 1.45-2.188 2.508-3.469 3.172s-2.84 1-4.672 1c-2.48 0-4.496-.722-6.047-2.172-1.543-1.445-2.312-3.32-2.312-5.625 0-2.812.96-4.867 2.89-6.171 1.938-1.313 4.97-1.97 9.094-1.97h4.516v-.609c0-1.207-.48-2.093-1.438-2.656-.949-.562-2.437-.844-4.468-.844-1.637 0-3.157.168-4.563.5a15.698 15.698 0 0 0-3.937 1.47v-5.829a41.147 41.147 0 0 1 4.937-.906 39.87 39.87 0 0 1 4.953-.313c4.32 0 7.438.856 9.344 2.563 1.914 1.7 2.875 4.46 2.875 8.281z" />
        </symbol>
        <symbol id="e" overflow="visible">
          <path d="M12.031-30.719v6.797h7.875v5.469h-7.875v10.14c0 1.118.219 1.872.656 2.266.438.387 1.313.578 2.625.578h3.938v5.47h-6.563C9.664 0 7.523-.63 6.265-1.89c-1.261-1.258-1.89-3.399-1.89-6.422v-10.141H.578v-5.47h3.797v-6.796z" />
        </symbol>
        <symbol id="f" overflow="visible">
          <path d="M19.953-20.422v-12.812h7.688V0h-7.688v-3.453c-1.055 1.406-2.215 2.437-3.484 3.094-1.274.656-2.743.984-4.407.984-2.949 0-5.37-1.172-7.265-3.516C2.91-5.234 1.969-8.25 1.969-11.937s.941-6.704 2.828-9.047C6.69-23.328 9.113-24.5 12.062-24.5c1.657 0 3.118.336 4.391 1 1.281.656 2.445 1.684 3.5 3.078zM14.906-4.938c1.645 0 2.895-.598 3.75-1.797.863-1.195 1.297-2.93 1.297-5.203 0-2.281-.434-4.02-1.297-5.219-.855-1.195-2.105-1.797-3.75-1.797-1.625 0-2.871.602-3.734 1.797-.856 1.2-1.282 2.938-1.282 5.219 0 2.273.426 4.008 1.282 5.203.863 1.2 2.11 1.797 3.734 1.797z" />
        </symbol>
        <symbol id="g" overflow="visible">
          <path d="M16.406-4.938c1.633 0 2.883-.597 3.75-1.796.863-1.196 1.297-2.93 1.297-5.204 0-2.28-.434-4.019-1.297-5.218-.867-1.196-2.117-1.797-3.75-1.797-1.637 0-2.89.605-3.766 1.812-.875 1.2-1.312 2.934-1.312 5.203 0 2.262.437 3.996 1.312 5.203.875 1.2 2.13 1.797 3.766 1.797zM11.328-20.42c1.05-1.395 2.219-2.422 3.5-3.079 1.281-.664 2.754-1 4.422-1 2.945 0 5.367 1.172 7.265 3.516 1.895 2.344 2.844 5.36 2.844 9.047s-.949 6.703-2.844 9.047C24.617-.546 22.195.625 19.25.625c-1.668 0-3.14-.336-4.422-1s-2.45-1.691-3.5-3.078V0H3.672v-33.234h7.656z" />
        </symbol>
        <symbol id="h" overflow="visible">
          <path d="M.531-23.922h7.656L14.61-7.688l5.47-16.234h7.655L17.672 2.266c-1.011 2.664-2.195 4.523-3.547 5.578-1.343 1.063-3.12 1.594-5.328 1.594H4.375V4.422h2.391c1.3 0 2.242-.21 2.828-.625.594-.406 1.055-1.148 1.39-2.219l.204-.656z" />
        </symbol>
        <symbol id="i" overflow="visible">
          <path d="M4.016-31.891h22.203v6.219H12.235v5.937h13.14v6.22h-13.14V0h-8.22z" />
        </symbol>
        <symbol id="j" overflow="visible">
          <path d="M3.672-23.922h7.656V0H3.672zm0-9.313h7.656V-27H3.672z" />
        </symbol>
        <symbol id="k" overflow="visible">
          <path d="M15.703-17.75c1.719 0 2.953-.316 3.703-.953.75-.645 1.125-1.703 1.125-3.172 0-1.445-.375-2.484-1.125-3.11-.75-.632-1.984-.953-3.703-.953h-3.469v8.188zm-3.469 5.688V-.002H4.015v-31.89h12.562c4.196 0 7.274.703 9.235 2.109 1.957 1.406 2.937 3.637 2.937 6.687 0 2.106-.511 3.836-1.53 5.188-1.013 1.355-2.544 2.355-4.595 3 1.125.25 2.13.828 3.016 1.734.894.907 1.797 2.278 2.703 4.11l4.469 9.062h-8.766l-3.875-7.922c-.793-1.593-1.59-2.68-2.39-3.265-.805-.582-1.875-.875-3.22-.875z" />
        </symbol>
        <symbol id="l" overflow="visible">
          <path d="M27.734-14.562V0h-7.703v-11.109c0-2.094-.047-3.531-.14-4.313-.087-.78-.243-1.359-.47-1.734a3.15 3.15 0 0 0-1.218-1.156c-.512-.281-1.094-.422-1.75-.422-1.594 0-2.852.617-3.766 1.844-.906 1.23-1.36 2.937-1.36 5.125V0H3.673v-33.234h7.656v12.812c1.144-1.395 2.363-2.422 3.656-3.078 1.3-.664 2.738-1 4.312-1 2.758 0 4.852.851 6.282 2.547 1.437 1.687 2.156 4.152 2.156 7.39z" />
        </symbol>
        <symbol id="m" overflow="visible">
          <path d="M25.844-19.953c.969-1.477 2.117-2.602 3.453-3.375 1.332-.781 2.797-1.172 4.39-1.172 2.75 0 4.845.852 6.282 2.547 1.437 1.688 2.156 4.152 2.156 7.39V0h-7.687v-12.47c.007-.187.02-.378.03-.578.009-.195.016-.484.016-.859 0-1.695-.25-2.926-.75-3.687-.5-.758-1.308-1.141-2.421-1.141-1.45 0-2.57.602-3.36 1.797-.793 1.2-1.203 2.93-1.234 5.187V0H19.03V-12.47c0-2.656-.23-4.363-.687-5.125-.45-.757-1.258-1.14-2.422-1.14-1.469 0-2.606.605-3.406 1.812-.793 1.2-1.188 2.918-1.188 5.156V0H3.625v-23.922h7.703v3.5c.938-1.352 2.016-2.368 3.235-3.047 1.218-.688 2.554-1.032 4.015-1.032 1.656 0 3.113.403 4.375 1.204 1.27.792 2.235 1.906 2.89 3.343z" />
        </symbol>
        <symbol id="n" overflow="visible">
          <path d="M27.734-14.562V0h-7.703v-11.156c0-2.063-.047-3.484-.14-4.266-.087-.78-.243-1.359-.47-1.734a3.15 3.15 0 0 0-1.218-1.156c-.512-.281-1.094-.422-1.75-.422-1.594 0-2.852.617-3.766 1.844-.906 1.23-1.36 2.937-1.36 5.125V0H3.673v-23.922h7.656v3.5c1.144-1.395 2.363-2.422 3.656-3.078 1.3-.664 2.738-1 4.312-1 2.758 0 4.852.851 6.282 2.547 1.437 1.687 2.156 4.152 2.156 7.39z" />
        </symbol>
      </defs>
      <path d="M350.09 12.773c-109.98 0-196.17 83.652-196.17 190.49 0 106.84 101.15 274.66 188.21 341.34 2.363 1.836 5.164 2.71 7.96 2.71 2.802 0 5.603-.874 7.962-2.71 86.887-66.676 187.95-230.3 187.95-341.25.004-110.95-86.008-190.57-195.91-190.57zm0 504.52c-78.836-65.539-169.93-215.34-169.93-314.04 0-92.051 74.637-164.24 169.93-164.24 95.289 0 169.66 72.102 169.66 164.24 0 98.613-90.914 248.5-169.66 314.04z" />
      <path d="M350.09 117.95c-54.863 0-99.398 44.625-99.398 99.398 0 54.773 44.625 99.227 99.398 99.227 54.773 0 99.227-44.539 99.227-99.227s-44.539-99.398-99.227-99.398zm0 172.38c-40.336 0-73.148-32.727-73.148-72.977s32.812-73.148 73.148-73.148 72.977 32.812 72.977 73.148-32.727 72.977-72.977 72.977z" />
    </svg>
  )
}

export default Location
