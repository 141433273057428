import React from 'react'
import { ToastProvider } from 'react-toast-notifications'

export const Notification = props => {
  return (
    <ToastProvider autoDismiss autoDismissTimeout={6000} placement="top-right">
      {props.children}
    </ToastProvider>
  )
}

export default Notification