import React from 'react'
import _ from 'lodash'
import validate from 'validate.js'
import numeral from 'numeral'
import moment from 'moment'
import { defaultTrip } from '../../reducers/initialState'

export const validatePayment = (quotation, models, context, bookingDetails) => {
  const modeofpayment = _.find(bookingDetails.payment.options, { checked: true })

  // - documentation @ https://validatejs.org/
  validate.extend(validate.validators.datetime, {
    // The value is guaranteed not to be null or undefined but otherwise it
    // could be anything.
    parse: function(value, options) {
      const date = _.isString(value) ? moment(value, 'MMYY') : value
      return moment.utc(date)
    },
    // Input is a unix timestamp
    format: function(value, options) {
      const format = options.dateOnly ? 'MMYY' : 'YYYY-MM-DD hh:mm:ss'
      const date = _.isString(value) ? moment(value, format) : value
      return moment.utc(value).format(format)
    },
  })

  let constraints = {
    agreedtoterms: {
      presence: { message: '^Must agree to our terms and conditions' },
      inclusion: {
        within: [true],
        message: '^Must agree to our terms and conditions',
      },
    },
  }

  if (modeofpayment.requirePayment) {
    constraints = {
      ...constraints,
      name: {
        presence: { allowEmpty: false },
        length: { maximum: 50 },
      },
      number: {
        presence: { allowEmpty: false },
        numericality: true,
        length: { minimum: 12, maximum: 19 },
      },
      expiry: {
        datetime: {
          earliest: moment.utc(),
          message: '^Card is expired',
        },
        presence: { allowEmpty: false },
      },
      cvc: {
        numericality: true,
        presence: { allowEmpty: false },
        length: { minimum: 3, maximum: 4 },
      },
    }
  }

  const fields = {
    ...bookingDetails.payment,
    agreedtoterms: bookingDetails.agreedtoterms,
  }

  return validate(fields, constraints, { fullMessages: false })
}

export const getCostBreakdown = (quotation, models, context) => {
  const currentTrip = quotation.trips[quotation.trip]
  const excludeChargesArray = ['ATH PAD', 'SUBTOTAL', 'ic fee']

  // - todo: needs to be smarter from models and modes
  const mode = quotation.mode == 'checkin' ? 'default' : quotation.mode

  const [excludedCharges, noExcludedCharges] = _.partition(
    currentTrip.estimate.Charge,
    c => excludeChargesArray?.indexOf(c['@attributes']?.desc?.toUpperCase()) != -1,
  )
  const [daysCharge, noDays] = _.partition(noExcludedCharges, c => c['@attributes'].drive == '1')
  const [coverCharge, noCover] = _.partition(
    noDays,
    c => _.find(models.covers[mode], { code: c['@attributes'].code }) != undefined,
  )
  const [extraCharges, otherCharges] = _.partition(
    noCover,
    c => ['GST', 'DISCNT'].indexOf(c['@attributes'].desc.toUpperCase()) == -1,
  )

  // - take note of the numeral wrapping the estimate item total. numeral understands negative postfix so it's a perfect solution for Thermeon's negative value format e.g DISCNT.
  const total = _.round(
    _.sumBy(currentTrip.estimate.Charge, c =>
      excludeChargesArray.indexOf(c['@attributes'].desc) == -1
        ? numeral(c['@attributes'].total).value()
        : 0,
    ),
    2,
  )

  let overAllTotal = total

  if (quotation.split) {
    const otherTrip = _.find([1, 2], function(t) {
      return t != quotation.trip
    })

    const otherTotal = _.round(
      _.sumBy(quotation.trips[otherTrip].estimate.Charge, c =>
        excludeChargesArray.indexOf(c['@attributes'].desc) == -1
          ? numeral(c['@attributes'].total).value()
          : 0,
      ),
      2,
    )

    overAllTotal = _.round(total + otherTotal, 2)
  }

  return {
    daysCharge,
    coverCharge,
    extraCharges,
    otherCharges,
    total,
    overAllTotal,
  }
}

export const getLocales = (quotation, models, context) => {}

export const getPaymentOptions = (quotation, models, context) => {
  const { mode } = quotation
  const currentTrip = quotation.trips[quotation.trip]
  const { total } = getCostBreakdown(quotation, models, context)

  const deposit = {
    id: 0,
    checked: true,
    title: 'Pay 10% Deposit',
    costRaw: total * 0.1,
    cost: `${numeral(total * 0.1).format('$0,0.00')} (incl. GST)`,
    inputName: 'paymentoption',
    inputValue: 'ten-percent',
    requirePayment: total > 0,
    text: (
      <React.Fragment>
        * + {numeral(total * 0.9).format('$0,0.00')} on pickup
        <br />
        (See Below for details)
      </React.Fragment>
    ),
  }

  const fullAmount = {
    id: 1,
    checked: true,
    title: 'Pay Full Amount"',
    costRaw: total,
    cost: `${numeral(total).format('$0,0.00')} (incl. GST)`,
    inputName: 'paymentoption',
    inputValue: 'full',
    requirePayment: total > 0,
    text: '',
  }

  const payonarrival = {
    id: 2,
    checked: true,
    title: 'Pay On Arrival',
    costRaw: 0,
    cost: `${numeral(0).format('$0,0.00')} (incl. GST)`,
    inputName: 'paymentoption',
    inputValue: 'payonarrival',
    requirePayment: false,
    text: (
      <React.Fragment>
        * + {numeral(total).format('$0,0.00')} on pickup
        <br />
        (See Below for details)
      </React.Fragment>
    ),
  }

  if (mode == 'default') {
    if (quotation.depositAllowed) {
      fullAmount.checked = false
      return [deposit, fullAmount]
    } else {
      return [fullAmount]
    }
  } else if (mode == 'checkin') {
    const po = [payonarrival]
    if (fullAmount.costRaw > 0) {
      fullAmount.checked = false
      po.push(fullAmount)
    }
    return po
  } else {
    const partner = _.find(models.partners, { mode })
    switch (partner.mode_of_payment) {
      case 'payonarrival':
        return [payonarrival]
      case 'prepayfullordeposit':
        fullAmount.checked = false
        return [deposit, fullAmount]
      case 'prepayfull':
        return [fullAmount]
      case 'prepaydeposit':
        return [deposit]
    }
  }
}
