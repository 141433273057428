import React from 'react'

function GPS(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="1 4 50 44"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M4 35.05l4.97.96V48H2.63c-.51 0-1-.25-1.3-.68-.31-.43-.4-.98-.27-1.5L4 35.06zM47.76 34l3.18 10.94a1.6 1.6 0 01-.27 1.42c-.31.4-.8.64-1.32.64H32l15.76-13zM12 36l24 4.36L28.02 47H12V36zm5.09-10a42.49 42.49 0 006.4 7.6 3.93 3.93 0 005.27 0 42.4 42.4 0 006.4-7.6h8.98c.74 0 1.4.5 1.6 1.25L47 31.8 38.53 39 5 32.67l1.5-5.42c.2-.74.86-1.25 1.6-1.25h8.99zM26 4c6.02 0 10.91 4.75 10.91 10.58 0 8.43-9.4 16.56-9.8 16.9a1.7 1.7 0 01-2.22 0c-.4-.34-9.8-8.47-9.8-16.9C15.09 8.75 19.99 4 26 4zm0 7.82a2.8 2.8 0 00-2.83 2.76c0 1.53 1.27 2.77 2.83 2.77s2.83-1.24 2.83-2.77-1.27-2.76-2.83-2.76z"
            />
        </svg>
    )
}

export default GPS
