import React from 'react'

function Address(props) {
    return (
		<svg 
			viewBox="0 0 64 63" 
			fill="none" 
			xmlns="http://www.w3.org/2000/svg"
			height={props.height}
			width={props.width}
		>
			<path className="svg-stroke" d="M50.3037 17.7842H13.6895C11.8093 17.7842 10.2852 19.3066 10.2852 21.1846V42.8154C10.2852 44.6934 11.8093 46.2158 13.6895 46.2158H50.3037C52.1839 46.2158 53.708 44.6934 53.708 42.8154V21.1846C53.708 19.3066 52.1839 17.7842 50.3037 17.7842Z" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
			<path className="svg-stroke" d="M22.826 32.1214C24.7331 32.1214 26.279 30.5772 26.279 28.6724C26.279 26.7676 24.7331 25.2234 22.826 25.2234C20.919 25.2234 19.373 26.7676 19.373 28.6724C19.373 30.5772 20.919 32.1214 22.826 32.1214Z" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
			<path className="svg-stroke" d="M28.7522 38.1799H16.8926C16.8926 34.9113 19.5466 32.2534 22.8259 32.2534C26.1052 32.2534 28.7592 34.9044 28.7592 38.1799H28.7522Z" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
			<path className="svg-stroke" d="M35.3027 27.229H43.3134" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
			<path className="svg-stroke" d="M35.3027 31.948H46.8845" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
			<path className="svg-stroke" d="M35.3027 36.3198H46.8845" strokeWidth="2.27" strokeLinecap="round" strokeLinejoin="round"/>
		</svg>
    )
}

export default Address