/* eslint-disable react/prop-types */
import React, { Fragment, useEffect, Suspense, lazy } from 'react'
import { Route, Switch } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { useToasts } from 'react-toast-notifications'
import { omit, filter, isEmpty, isArray, includes } from 'lodash'

import Context, { demystify } from './Context'
import { LocationProvider } from '../contexts/Locations'
import LocationIndex from './ui/Locations/LocationIndex'

const Itinerary = lazy(() => import('./containers/Itinerary'))
const Checkin = lazy(() => import('./containers/Checkin'))
const SearchResult = lazy(() => import('./containers/SearchResult'))
const Create = lazy(() => import('./containers/Create'))
const Confirmation = lazy(() => import('./containers/Confirmation'))
const Panel = lazy(() => import('./ui/BlueChipSignup/Panel'))
const TravelAgent = lazy(() => import('./containers/TravelAgent'))

const SanitiseErrors = (errmsg, rates = []) => {
  if (errmsg.indexOf('LT:') != -1) {
    const hr = errmsg.match(/LT(:\d)H/)
    return `The Selected location requires ${hr[1]} hour/s lead time for pickup`
  } else {
    const mindays = errmsg.match(/(\d+) Day\(2061\)/)
    if (mindays) {
      const availability = filter(rates, r => r.Availability == 'Available')
      if (isEmpty(availability)) {
        return `There is a minimum rental length of ${mindays[1]} days on some vehicles, please change your date selection`
      } else {
        return ''
      }
    } else {
      return errmsg
    }
  }
}

const App = () => {
  console.log('Deploy v241101.5')
  const dispatch = useDispatch()
  const messages = useSelector(state => state.app.messages)
  const quotation = useSelector(state => state.quotation)
  const currentTrip = quotation.trips[quotation.trip]
  const currentRate = currentTrip.rates[quotation.mode]
  const { addToast } = useToasts()

  useEffect(() => {
    if (isArray(messages)) {
      messages.map(m => {
        const err = SanitiseErrors(m.Text, currentRate)
        if (err != '') {
          addToast(err, {
            appearance: m['@attributes'].type.toLowerCase(),
            autoDismiss: false,
          })
        }
      })
    } else {
      if (!isEmpty(messages)) {
        const err = SanitiseErrors(messages.Text, currentRate)
        if (err != '') {
          addToast(err, {
            appearance: messages['@attributes'].type.toLowerCase(),
            autoDismiss: false,
          })
        }
      }
    }

    if (!isEmpty(messages)) {
      dispatch({ type: 'clear.ex', error: [] })
    }
  }, [addToast, currentRate, dispatch, messages])

  return (
    <Context.Provider value={omit(demystify(data), ['Header', 'Footer'])}>
      <LocationProvider>
        <Suspense fallback={<Fragment></Fragment>}>
          {!includes(
            ['/car-sales', '/checkin', '/bookings/confirmation'],
            window.location.pathname,
          ) &&
            quotation.mode != 'checkin' && <Itinerary />}

          <Switch>
            <Route exact path={`/checkin`} component={Checkin} />
            <Route exact path={`/bookings/search`} component={SearchResult} />
            <Route exact path={`/bookings/create`} component={Create} />
            <Route exact path={`/bookings/confirmation`} component={Confirmation} />
            <Route exact path={`/locations`} component={LocationIndex} />
            <Route exact path={`/travelagent`} component={TravelAgent} />
          </Switch>

          <Panel />
        </Suspense>
      </LocationProvider>
    </Context.Provider>
  )
}

export default App
