import React from 'react'

function Minus(props) {
  return (
    <svg
      height={props.height}
      width={props.width}
      viewBox="0 0 10 3"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M1 1.16699H9" stroke={props.colorOne} strokeWidth="2" strokeLinecap="round" />
    </svg>
  )
}

export default Minus
