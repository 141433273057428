import { takeEvery, put, call } from 'redux-saga/effects'
import callApi, { callApiRates } from './helpers'
// force update
const getConfig = params => {
  return {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(params),
  }
}

export function* getRates(params) {
  try {
    yield call(callApiRates, () => fetch('/api', getConfig(params)), 'getRates')
  } catch (error) {
    yield put({ type: 'getRates.ex', error: error })
  }
}

export function* getPromoRates(params) {
  try {
    yield call(callApi, () => fetch('/api', getConfig(params)), 'getPromoRates')
  } catch (error) {
    yield put({ type: 'getPromoRates.ex', error: error })
  }
}

export function* watchRatesRequest() {
  // yield takeLatest('getRates', getRates);
  yield takeEvery('getRates', getRates)
  // yield takeLatest('getPromoRates', getPromoRates);
  yield takeEvery('getPromoRates', getPromoRates)
}
