import { takeEvery, put, call } from 'redux-saga/effects'
import callApi from './helpers'

const getConfig = params => {
  return {
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
    body: JSON.stringify(params),
  }
}

export function* getEstimate(params) {
  try {
    yield call(callApi, () => fetch('/api', getConfig(params)), 'getEstimate')
  } catch (error) {
    yield put({ type: 'getEstimate.ex', error: error })
  }
}

export function* watchEstimateRequest() {
  yield takeEvery('getEstimate', getEstimate)
}
