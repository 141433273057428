import React from 'react'

function Speed(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 15 88 46"
      height={props.height}
      width={props.width}
    >
      <path
        fill={props.colorOne}
        d="M59.58 56c-.73-3.9-3-7.27-6.17-9.5l6.69-13.3c.68-1.35.12-3-1.27-3.67a2.82 2.82 0 00-3.75 1.25l-6.74 13.4a15.58 15.58 0 00-3.84-.48c-7.5 0-13.76 5.31-15.08 12.3H6a37.3 37.3 0 019.31-22l1.91 1.88a2.82 2.82 0 003.96 0 2.7 2.7 0 000-3.88l-1.91-1.87A38.84 38.84 0 0141.7 21v3.12c0 1.52 1.25 2.75 2.8 2.75s2.8-1.23 2.8-2.75V21c8.54.6 16.32 3.94 22.43 9.13L67.82 32a2.7 2.7 0 000 3.88 2.82 2.82 0 003.96 0l1.9-1.87C78.99 40 82.39 47.63 83 56H59.58zm-14.4-7.05a9 9 0 00-1.25-.09 9.57 9.57 0 00-9.1 7.14H33c1.22-5.16 5.65-9 10.93-9 .7 0 1.4.07 2.07.2l-.82 1.75zM55 55h-1.94A9.32 9.32 0 0050 50.6l.85-1.6c2.02 1.5 3.5 3.6 4.15 6zm20.35-27.07a2.6 2.6 0 00-.5-.49A44.24 44.24 0 0044 15H44a44.24 44.24 0 00-31.35 12.93A42.75 42.75 0 000 58.26 2.76 2.76 0 002.79 61H85.2A2.76 2.76 0 0088 58.26c0-11.8-4.83-22.51-12.65-30.32z"
      />
    </svg>
  )
}

export default Speed
