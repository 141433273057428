import React from 'react'

function SearchCar(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            enableBackground="new 0 0 24 24"
            viewBox="0 0 24 24"
            version="1.1"
            height={props.height}
            width={props.width}
        >
            <g id="Frame_-_24px">
                <rect fill="none" height="24.002" width="24" x="0.002" y="0"></rect>
            </g>
            <g id="Filled_Icons">
                <g>
                    <path
                        d="M16.488,12.975L18,16H4l2-4h6.408c-0.849-0.509-1.582-1.19-2.148-2H5l-4,7v5h2c0,1.104,0.895,2,2,2    s2-0.896,2-2h8c0,1.104,0.896,2,2,2s2-0.896,2-2h2v-5l-2.544-4.451C17.836,12.781,17.177,12.928,16.488,12.975z M8,20H3v-2h5V20z     M19,20h-5v-2h5V20z"
                        fill={props.colorOne}
                    ></path>
                    <path
                        d="M20.167,8.753C20.691,7.963,21,7.018,21,6c0-2.757-2.243-5-5-5s-5,2.243-5,5s2.243,5,5,5    c1.018,0,1.963-0.309,2.753-0.833L22,13.414L23.414,12L20.167,8.753z M16,9c-1.654,0-3-1.346-3-3s1.346-3,3-3s3,1.346,3,3    S17.654,9,16,9z"
                        fill={props.colorOne}
                    ></path>
                </g>
            </g>
        </svg>
    )
}

export default SearchCar
