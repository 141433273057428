import React from 'react'

function Money(props) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="23 0 42 83"
            height={props.height}
            width={props.width}
        >
            <path
                fill={props.colorOne}
                d="M55.27 37.7c-3.18-1.8-6.56-3.17-9.9-4.59a22.13 22.13 0 01-5.45-3.12c-3.23-2.64-2.61-6.92 1.17-8.62a9.37 9.37 0 013.33-.7c4.4-.25 8.56.58 12.53 2.53 1.97.98 2.63.67 3.3-1.44.7-2.23 1.28-4.49 1.94-6.74.43-1.5-.1-2.5-1.5-3.13a31.31 31.31 0 00-7.9-2.42c-3.58-.57-3.58-.58-3.6-4.27C49.19 0 49.19 0 44.1 0h-2.2c-2.39.08-2.79.5-2.86 2.96-.03 1.1 0 2.19-.01 3.3-.02 3.26-.04 3.21-3.08 4.34-7.37 2.75-11.92 7.89-12.4 16.12-.44 7.28 3.27 12.2 9.12 15.78 3.6 2.21 7.58 3.52 11.4 5.25 1.49.67 2.91 1.44 4.15 2.5 3.67 3.1 3 8.26-1.36 10.22a13.37 13.37 0 01-7.31.98 32.1 32.1 0 01-11.15-3.42c-2.06-1.1-2.67-.8-3.37 1.48-.6 1.97-1.14 3.96-1.67 5.95-.72 2.67-.45 3.3 2.04 4.56 3.18 1.58 6.58 2.38 10.04 2.95 2.72.44 2.8.56 2.83 3.44l.04 3.93c.02 1.64.79 2.6 2.44 2.64 1.88.03 3.77.03 5.64-.02 1.54-.03 2.33-.9 2.33-2.49 0-1.78.09-3.58.02-5.36-.09-1.82.69-2.74 2.4-3.22a18.88 18.88 0 009.85-6.48c7.17-8.92 4.44-21.96-5.7-27.7"
            />
        </svg>
    )
}

export default Money
